import { Layout, Modal, Spin } from 'antd';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
// Localization
import { getActiveLanguage, Language, LocalizeContextProps, setActiveLanguage, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
// Styling
import './App.less';
// Layouts
import RouteWithLayout from './components/shared/RouteWithLayout';
// Security
import WithAuthorization from './components/shared/WithAuthorization';
import Routes from './config/Routes';
import FeatureFlag from './consts/FeatureFlag';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import PublicLayout from './layouts/PublicLayout';
import { DefaultLanguage, GetLanguages, OnMissingTranslation, SupportedLanguages } from './localization/localizationConfiguration';
import UserSecurity from './models/UserSecurity';
// --- Secondary Home
import PostSecondaryLandingPage from './pages/postSecondary/PostSecondaryLandingPage'
// -- Announcements Pages
import AnnouncementDetailPage from './pages/announcements/AnnouncementDetailsPage';
import AnnouncementListPage from './pages/announcements/AnnouncementListPage';
import PastAnnoncementsPage from './pages/announcements/PastAnnouncementsPage';
// -- Authentication Pages
import ConfirmEmailPage from './pages/authentication/ConfirmEmailPage';
import LoginPage from './pages/authentication/LoginPage';
import RequestResetPasswordPage from './pages/authentication/RequestResetPasswordPage';
import ResendConfirmationEmailPage from './pages/authentication/ResendConfirmationEmailPage';
import ResetPasswordPage from './pages/authentication/ResetPasswordPage';
// -- Change Request
import ChangeRequestDetailsPage from './pages/postSecondary/changeRequests/ChangeRequestDetailsPage';
import ChangeRequestDraftPage from './pages/postSecondary/changeRequests/ChangeRequestDraftPage';
import ChangeRequestListPage from './pages/postSecondary/changeRequests/ChangeRequestListPage';
import CourseModificationChangeRequestReviewPage from './pages/postSecondary/changeRequests/modifyCourse/CourseModificationChangeRequestReviewPage';
// -- Change Request - Admin Change Request
import AdministrativeChangeRequestStep1Page from './pages/postSecondary/changeRequests/administrative/AdministrativeChangeRequestStep1Page';
import AdministrativeChangeRequestStep2Page from './pages/postSecondary/changeRequests/administrative/AdministrativeChangeRequestStep2Page';
import AdministrativeChangeRequestReviewPage from './pages/postSecondary/changeRequests/administrative/AdministrativeChangeRequestReviewPage';
import AdministrativeChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/administrative/AdministrativeChangeRequestSubmittedPage';
// -- Change Request - Course Deactivation Request
import CourseDeactivationChangeRequestStep1Page from './pages/postSecondary/changeRequests/courseDeactivation/CourseDeactivationChangeRequestStep1Page';
import CourseDeactivationChangeRequestStep2Page from './pages/postSecondary/changeRequests/courseDeactivation/CourseDeactivationChangeRequestStep2Page';
import CourseDeactivationChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/courseDeactivation/CourseDeactivationChangeRequestSubmittedPage';
import CourseDeactivationChangeRequestReviewPage from './pages/postSecondary/changeRequests/courseDeactivation/CourseDeactivationChangeRequestReviewPage';
// -- Change Request - Course Modification Request
import CourseModificationChangeRequestStep1Page from './pages/postSecondary/changeRequests/modifyCourse/CourseModificationChangeRequestStep1Page';
import CourseModificationChangeRequestStep2Page from './pages/postSecondary/changeRequests/modifyCourse/CourseModificationChangeRequestStep2Page';
import CourseModificationChangeRequestStep3Page from './pages/postSecondary/changeRequests/modifyCourse/CourseModificationChangeRequestStep3Page';
import CourseModificationChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/modifyCourse/CourseModificationChangeRequestSubmittedPage';
import NewCourseChangeRequestReviewPage from './pages/postSecondary/changeRequests/newCourse/NewCourseChangeRequestReviewPage';
// -- Change Request - New Course Request
import NewCourseChangeRequestStep1Page from './pages/postSecondary/changeRequests/newCourse/NewCourseChangeRequestStep1Page';
import NewCourseChangeRequestStep2Page from './pages/postSecondary/changeRequests/newCourse/NewCourseChangeRequestStep2Page';
import NewCourseChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/newCourse/NewCourseChangeRequestSubmittedPage';
import NewDisciplineChangeRequestReviewPage from './pages/postSecondary/changeRequests/newDiscipline/NewDisciplineChangeRequestReviewPage';
// -- Change Request - New Discipline Request
import NewDisciplineChangeRequestStep1Page from './pages/postSecondary/changeRequests/newDiscipline/NewDisciplineChangeRequestStep1Page';
import NewDisciplineChangeRequestStep2Page from './pages/postSecondary/changeRequests/newDiscipline/NewDisciplineChangeRequestStep2Page';
import NewDisciplineChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/newDiscipline/NewDisciplineChangeRequestSubmittedPage';
import NoticeOfIntentChangeRequestReviewPage from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestReviewPage';
// -- Change Request - Notice of Intent
import NoticeOfIntentChangeRequestStep1Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep1Page';
import NoticeOfIntentChangeRequestStep2Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep2Page';
import NoticeOfIntentChangeRequestStep3Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep3Page';
import NoticeOfIntentChangeRequestStep4Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep4Page';
import NoticeOfIntentChangeRequestStep5Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep5Page';
import NoticeOfIntentChangeRequestStep6Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep6Page';
import NoticeOfIntentChangeRequestStep7Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep7Page';
import NoticeOfIntentChangeRequestStep8Page from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestStep8Page';
import NoticeOfIntentChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/noticeOfIntent/NoticeOfIntentChangeRequestSubmittedPage';
// -- Change Request - Program Deactivation Request
import ProgramDeactivationChangeRequestStep1Page from './pages/postSecondary/changeRequests/programDeactivation/ProgramDeactivationChangeRequestStep1Page';
import ProgramDeactivationChangeRequestStep2Page from './pages/postSecondary/changeRequests/programDeactivation/ProgramDeactivationChangeRequestStep2Page';
import ProgramDeactivationChangeRequestReviewPage from './pages/postSecondary/changeRequests/programDeactivation/ProgramDeactivationChangeRequestReviewPage';
import ProgramDeactivationChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/programDeactivation/ProgramDeactivationChangeRequestSubmittedPage';
// -- Award Types
import AwardTypeDetailsPage from './pages/configurations/postSecondary/awardTypes/AwardTypeDetailsPage';
import AwardTypeListPage from './pages/configurations/postSecondary/awardTypes/AwardTypeListPage';
// -- APS General Education
import APSGeneralEducationDetailPage from './pages/configurations/postSecondary/apsGeneralEducations/APSGeneralEducationDetailPage';
import APSGeneralEducationListPage from './pages/configurations/postSecondary/apsGeneralEducations/APSGeneralEducationListPage';
// -- Category
import CategoryDetailsPage from './pages/configurations/postSecondary/categories/CategoryDetailsPage';
import CategoryListPage from './pages/configurations/postSecondary/categories/CategoryListPage';
import CipNumberDetailsPage from './pages/configurations/postSecondary/cips/CipNumberDetailsPage';
// Cips
import CipNumberListPage from './pages/configurations/postSecondary/cips/CipNumberListPage';
// Program Classification
import ProgramClassificationListPage from './pages/configurations/postSecondary/programClassification/ProgramClassificationListPage';
import ProgramClassificationDetailsPage from './pages/configurations/postSecondary/programClassification/ProgramClassificationDetailsPage';
// Notice Of Intent Change Request WorkForce Link
import NoticeOfIntentChangeRequestWorkForceLinkListPage from './pages/configurations/postSecondary/noticeOfIntentChangeRequestWorkForceLinks/NoticeOfIntentChangeRequestWorkForceLinkListPage';
// -- Change Request - New Program
import NewProgramChangeRequestStep1Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep1Page';
import NewProgramChangeRequestStep2Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep2Page';
import NewProgramChangeRequestStep3Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep3Page';
import NewProgramChangeRequestStep4Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep4Page';
import NewProgramChangeRequestStep5Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep5Page';
import NewProgramChangeRequestStep6Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep6Page';
import NewProgramChangeRequestStep7Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep7Page';
import NewProgramChangeRequestStep8Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep8Page';
import NewProgramChangeRequestStep9Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep9Page';
import NewProgramChangeRequestStep10Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep10Page';
import NewProgramChangeRequestStep11Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep11Page';
import NewProgramChangeRequestStep12Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep12Page';
import NewProgramChangeRequestStep13Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep13Page';
import NewProgramChangeRequestStep14Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep14Page';
import NewProgramChangeRequestStep15Page from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestStep15Page';
import NewProgramChangeRequestReviewPage from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestReviewPage';
import NewProgramChangeRequestSubmitPage from './pages/postSecondary/changeRequests/newProgram/NewProgramChangeRequestSubmitPage';
// -- Change Request - Program Modification
import ProgramModificationChangeRequestStep1Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep1Page';
import ProgramModificationChangeRequestStep2Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep2Page';
import ProgramModificationChangeRequestStep3Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep3Page';
import ProgramModificationChangeRequestStep4Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep4Page';
import ProgramModificationChangeRequestStep5Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep5Page';
import ProgramModificationChangeRequestStep6Page from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestStep6Page';
import ProgramModificationChangeRequestReviewPage from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestReviewPage';
import ProgramModificationChangeRequestSubmittedPage from './pages/postSecondary/changeRequests/programModification/ProgramModificationChangeRequestSubmittedPage';
// --  Workflow
import WorkflowListPage from './pages/configurations/workflow/WorkflowListPage'
import WorkflowDetailsPage from './pages/configurations/workflow/WorkflowDetailsPage'
// -- Configuration
import ConfigurationPage from './pages/configurations/ConfigurationPage';
import CreditTypeDetailsPage from './pages/configurations/postSecondary/creditTypes/CreditTypeDetailsPage';
// -- Contact
import ContactListPage from './pages/postSecondary/contacts/ContactListPage';
// -- Contact
import ContactTypeListPage from './pages/configurations/postSecondary/contactTypes/ContactTypesListPage';
import ContactTypeDetailsPage from './pages/configurations/postSecondary/contactTypes/ContactTypeDetailsPage';
// -- Credit Type 
import CreditTypeListPage from './pages/configurations/postSecondary/creditTypes/CreditTypeListPage';
// -- Discipline
import DisciplineDetailsPage from './pages/configurations/postSecondary/disciplines/DisciplineDetailsPage';
import DisciplineListPage from './pages/configurations/postSecondary/disciplines/DisciplineListPage';
// -- DocumentArchive
import DocumentArchiveListPage from './pages/documentArchive/DocumentArchiveListPage';
// -- Downloads Section
import DownloadsPage from './pages/postSecondary/downloadsSection/DownloadsPage';
//-- External Institutions
import ExternalInstitutionDetailsPage from './pages/configurations/postSecondary/externalInstitutions/ExternalInstitutionDetailsPage';
import ExternalInstitutionListPage from './pages/configurations/postSecondary/externalInstitutions/ExternalInstitutionListPage';
//-- Gender
import GenderDetailsPage from './pages/configurations/genders/GenderDetailsPage';
import GenderListPage from './pages/configurations/genders/GenderListPage';
// -- General Education
import GeneralEducationDetailPage from './pages/configurations/postSecondary/generalEducations/GeneralEducationDetailPage';
import GeneralEducationListPage from './pages/configurations/postSecondary/generalEducations/GeneralEducationListPage';
// Instructional Level
import InstructionalLevelDetailsPage from './pages/configurations/postSecondary/instructionalLevels/InstructionalLevelDetailsPage';
import InstructionalLevelListPage from './pages/configurations/postSecondary/instructionalLevels/InstructionalLevelListPage';
import IowaVariationDetailsPage from './pages/configurations/postSecondary/iowaVariations/IowaVariationDetailsPage';
// Iowa Variation
import IowaVariationListPage from './pages/configurations/postSecondary/iowaVariations/IowaVariationListPage';
import ObjectPurposeDetailsPage from './pages/configurations/postSecondary/objectPurpose/ObjectPurposeDetailsPage';
// Object Purpose
import ObjectPurposeListPage from './pages/configurations/postSecondary/objectPurpose/ObjectPurposeListPage';
// Programs
import ProgramDetailsPage from './pages/postSecondary/programs/ProgramDetailsPage';
import ProgramListPage from './pages/postSecondary/programs/ProgramListPage';
// Roles
import RoleDetailsPage from './pages/configurations/roles/RoleDetailsPage';
import RolesListPage from './pages/configurations/roles/RolesListPage';
// Racial/Ethnic Groups
import RacialEthnicGroupDetailsPage from './pages/configurations/racialEthnicGroups/RacialEthnicGroupDetailsPage';
import RacialEthnicGroupListPage from './pages/configurations/racialEthnicGroups/RacialEthnicGroupListPage';
// Special Emphasis
import SpecialEmphasisDetailsPage from './pages/configurations/postSecondary/specialEmphases/SpecialEmphasisDetailsPage';
import SpecialEmphasisListPage from './pages/configurations/postSecondary/specialEmphases/SpecialEmphasisListPage';
// -- Terms
import TermDetailsPage from './pages/configurations/postSecondary/terms/TermDetailsPage';
import TermListPage from './pages/configurations/postSecondary/terms/TermListPage';
import TypeOfProgramDetailsPage from './pages/configurations/postSecondary/typeOfPrograms/TypeOfProgramDetailsPage';
// -- Transfer Major Course Block
import TransferMajorCourseBlockDetailsPage from './pages/configurations/postSecondary/transferMajorCourseBlocks/TransferMajorCourseBlockDetailsPage';
import TransferMajorCourseBlockListPage from './pages/configurations/postSecondary/transferMajorCourseBlocks/TransferMajorCourseBlockListPage';
// -- Transfer Major Lacts Category
import TransferMajorLactsCategoryDetialsPage from './pages/configurations/postSecondary/transferMajorLactsCategories/TransferMajorLactsCategoryDetialsPage';
import TransferMajorLactsCategoryListPage from './pages/configurations/postSecondary/transferMajorLactsCategories/TransferMajorLactsCategoryListPage';
// -- Transfer Major Discipline Framework Element
import TransferMajorDisciplineFrameworkElementDetailsPage from './pages/configurations/postSecondary/transferMajorDisciplineFrameworkElements/TransferMajorDisciplineFrameworkElementDetailsPage';
import TransferMajorDisciplineFrameworkElementListPage from './pages/configurations/postSecondary/transferMajorDisciplineFrameworkElements/TransferMajorDisciplineFrameworkElementListPage';
// -- Transfer Major General Education Type
import TransferMajorGeneralEducationTypeDetailsPage from './pages/configurations/postSecondary/transferMajorGeneralEducationTypes/TransferMajorGeneralEducationTypeDetailsPage';
import TransferMajorGeneralEducationTypeListPage from './pages/configurations/postSecondary/transferMajorGeneralEducationTypes/TransferMajorGeneralEducationTypeListPage';
// Type Of Program
import TypeOfProgramListPage from './pages/configurations/postSecondary/typeOfPrograms/TypeOfProgramListPage';
// -- Third Party Credential Type
import ThirdPartyCredentialTypeDetailsPage from './pages/configurations/thirdPartyCredentialTypes/ThirdPartyCredentialTypeDetailsPage';
import ThirdPartyCredentialTypeListPage from './pages/configurations/thirdPartyCredentialTypes/ThirdPartyCredentialTypeListPage';
// -- Course
import CourseDetailsPage from './pages/postSecondary/courses/CourseDetailsPage';
import CourseListPage from './pages/postSecondary/courses/CourseListPage';
// -- Consultants
import publicConsultantsSearchPage from './pages/postSecondary/consultants/ConsultantsSearchPage';
// -- Resources
import AdminResourcePage from './pages/resources/AdminResourcePage';
import PublicResourcePage from './pages/resources/PublicResourcePage';
// -- Account Pages
import DashboardPage from './pages/DashboardPage';
// -- Institution Course Block Detail 
import InstitutionCourseBlockDetailsPage from './pages/postSecondary/institutionCourseBlocks/InstitutionCourseBlockDetailPage';
// -- Institution Course Pages
import InstitutionCourseDetailsPage from './pages/postSecondary/institutionCourses/InstitutionCourseDetailsPage';
// -- Institutions Pages
import InstitutionDetailPage from './pages/postSecondary/institutions/InstitutionDetailsPage';
import InstitutionListPage from './pages/postSecondary/institutions/InstitutionListPage';
// -- Institution User Pages
import InstitutionUserDetailsPage from './pages/postSecondary/institutionUsers/InstitutionUserDetailsPage';
import PublicLandingPage from './pages/PublicLandingPage';
// -- Users Pages
import UserDetailsPage from './pages/users/UserDetailsPage';
import UsersListPage from './pages/users/UsersListPage';
import UserSettingsPage from './pages/userSettings/UserSettingsPage';
// -- Work Items
import WorkItemListPage from './pages/workItems/WorkItemListPage';
// Work Based Learning Type
import WorkBasedLearningTypeListPage from './pages/configurations/postSecondary/workBaseLearningType/WorkBasedLearningTypeListPage';
import WorkBasedLearningTypeDetailsPage from './pages/configurations/postSecondary/workBaseLearningType/WorkBasedLearningTypeDetailsPage';
// Redux
import { UserSessionActions } from './redux/actions/UserSessionActions';
import { VersionActions } from './redux/actions/VersionActions';
import { StateStoreModel } from './redux/state/StateStoreModel';
// Utils
import History from './utils/HistoryUtil';
import { RedirectAs404 } from './utils/RouteErrors';
// --- Secondary
// --- Secondary Home
import SecondaryLandingPage from './pages/secondary/SecondaryLandingPage'
// -- Self Studies
import SelfStudiesListPage from './pages/secondary/selfStudies/SelfStudyListPage';
import SelfStudyReviewPage from './pages/secondary/selfStudies/ProgramsForReviewPage';
import SelfStudyDetailsPage from './pages/secondary/selfStudies/SelfStudyDetailPage';
import SelfStudyDetailDisplayPage from './pages/secondary/selfStudies/SelfStudyDetailDisplayPage';
import SelfStudyResponseDisplayPage from './pages/secondary/selfStudies/SelfStudyResponseDisplayPage';
import CompleteSelfStudyPage from './pages/secondary/selfStudies/CompleteSelfStudyPage';
import SelfStudiesPage from './pages/secondary/SelfStudiesPage';
import SelfStudyConfigurationLandingPage from './pages/secondary/selfStudies/SelfStudyConfigurationPage';
import AcademicYearDashboardPage from './pages/secondary/academicYear/AcademicYearDashboardPage';
import AcademicYearConfigurationPage from './pages/secondary/academicYear/AcademicYearConfigurationPage';
import AcademicYearPage from './pages/secondary/academicYear/AcademicYearPage';
import AcademicYearListPage from './pages/secondary/academicYear/AcademicYearListPage';
import SelfStudyRankingReportPage from './pages/secondary/academicYear/SelfStudyRankingReportPage';
import ProgramConsultantCommentPage from './pages/secondary/academicYear/ProgramConsultantCommentPage';
// -- Region
import RegionalPlanningPartnerListPage from './pages/secondary/regionalPlanningPartner/RegionalPlanningPartnerListPage'
import AcademicYearRegionalPlanningPartnerConfigurationPage from './pages/secondary/regionalPlanningPartner/AcademicYearRegionalPlanningPartnerConfigurationPage'
import RegionalPlanningPartnerDetailsPage from './pages/secondary/regionalPlanningPartner/RegionalPlanningPartnerDetailsPage';
import DataReviewRegionalPlanningPartnerListPage from './pages/secondary/regionalPlanningPartner/DataReviewRegionalPlanningPartnerListPage';
// -- District 
import DistrictListPage from './pages/secondary/district/DistrictListPage';
import AcademicYearDistrictConfigurationPage from './pages/secondary/district/AcademicYearDistrictConfigurationPage';
import DistrictDetailsPage from './pages/secondary/district/DistrictDetailsPage';
import DataReviewDistrictListPage from './pages/secondary/district/DataReviewDistrictListPage';
// -- District Contact
import DataReviewDistrictContactListPage from './pages/secondary/districtContact/DataReviewDistrictContactListPage';
import AcademicYearDistrictContactConfigurationPage from './pages/secondary/districtContact/AcademicYearDistrictContactConfigurationPage';
// -- Secondary Program
import SecondaryProgramListPage from './pages/secondary/programs/SecondaryProgramListPage';
import AcademicYearSecondaryProgramConfigurationPage from './pages/secondary/programs/AcademicYearSecondaryProgramConfigurationPage';
import SecondaryProgramDetailPage from './pages/secondary/programs/SecondaryProgramDetailPage';
import DataReviewSecondaryProgramListPage from './pages/secondary/programs/DataReviewSecondaryProgramListPage';
// -- Secondary Program Type
import SecondaryProgramScenarioListPage from './pages/secondary/programScenario/SecondaryProgramScenarioListPage';
import AcademicYearScenarioConfigurationPage from './pages/secondary/programScenario/AcademicYearScenarioConfigurationPage';
import SecondaryProgramScenarioDetailsPage from './pages/secondary/programScenario/SecondaryProgramScenarioDetailsPage';
import DataReviewSecondaryProgramScenarioListPage from './pages/secondary/programScenario/DataReviewSecondaryProgramScenarioListPage';
import ScenarioConfigurationListPage from './pages/configurations/secondary/scenario/ScenarioConfigurationListPage';
// -- Secondary Program Courses
import DataReviewSecondaryProgramCourseListPage from './pages/secondary/programCourses/DataReviewSecondaryProgramCourseListPage';
import AcademicYearProgramCoursesConfigurationPage from './pages/secondary/programCourses/AcademicYearProgramCoursesConfigurationPage';
// -- Secondary Courses
import SecondaryCourseListPage from './pages/secondary/courses/SecondaryCourseListPage';
import AcademicYearSecondaryCoursesConfigurationPage from './pages/secondary/courses/AcademicYearSecondaryCoursesConfigurationPage';
import SecondaryCourseDetailPage from './pages/secondary/courses/SecondaryCourseDetailPage';
import DataReviewSecondaryCourseListPage from './pages/secondary/courses/DataReviewSecondaryCourseListPage';
// -- Secondary Reports 
import ContactsReportPage from './pages/secondary/reports/ContactsReportPage';
import CareerAcademyReportPage from './pages/secondary/reports/CareerAcademyReportPage';
import ActionPlanReportPage from './pages/secondary/reports/ActionPlanReportPage';
import ScenarioReportPage from './pages/secondary/reports/ScenarioReportPage';
import ProgramMasterReportPage from './pages/secondary/reports/ProgramMasterReportPage';
import SelfStudyAggregateReportPage from './pages/secondary/reports/SelfStudyAggregateReportPage';
// -- Federal Cluster 
import FederalClusterListPage from './pages/secondary/federalCluster/FederalClusterListPage';
import AcademicYearFederalClusterConfigurationPage from './pages/secondary/federalCluster/AcademicYearFederalClusterConfigurationPage';
import FederalClustersConfigurationListPage from './pages/configurations/secondary/federalClusters/FederalClustersConfigurationListPage';
// import FederalClusterDetailsPage from './pages/secondary/federalCluster/FederalClusterDetailsPage'
import DataReviewFederalClusterListPage from './pages/secondary/federalCluster/DataReviewFederalClusterListPage';
// -- Secondary CIP Number
import SecondaryCipNumberListPage from './pages/secondary/cipNumber/SecondaryCipNumberListPage';
import AcademicYearSecondaryCipNumberConfigurationPage from './pages/secondary/cipNumber/AcademicYearSecondaryCipNumberConfigurationPage';
import SecondaryCipNumberDetailPage from './pages/secondary/cipNumber/SecondaryCipNumberDetailPage';
import DataReviewSecondaryCipNumberListPage from './pages/secondary/cipNumber/DataReviewSecondaryCipNumberListPage';
import SecondaryCipNumberConfigurationListPage from './pages/configurations/secondary/cipNumber/SecondaryCipNumberConfigurationListPage';
// -- Service Area
import ServiceAreaListPage from './pages/secondary/serviceArea/ServiceAreaListPage';
import AcademicYearServiceAreasConfigurationPage from './pages/secondary/serviceArea/AcademicYearServiceAreasConfigurationPage';
// import ServiceAreaDetailsPage from './pages/secondary/serviceArea/ServiceAreaDetailsPage'
import ServiceAreaConfigurationListPage from './pages/configurations/secondary/serviceArea/ServiceAreaConfigurationListPage';
import DataReviewServiceAreaListPage from './pages/secondary/serviceArea/DataReviewServiceAreaListPage';
// -- Area Edcuation Agency
import AreaEducationAgencyListPage from './pages/secondary/areaEducationAgency/AreaEducationAgencyListPage';
import AcademicYearAreaEducationAgencyConfigurationPage from './pages/secondary/areaEducationAgency/AcademicYearAreaEducationAgencyConfigurationPage';
import AreaEducationAgencyDetailPage from './pages/secondary/areaEducationAgency/AreaEducationAgencyDetailPage';
import ReviewAreaEducationAgencyAcdemicYearPage from './pages/secondary/areaEducationAgency/ReviewAreaEducationAgencyAcdemicYearPage';
import DataReviewAreaEducationAgencyListPage from './pages/secondary/areaEducationAgency/DataReviewAreaEducationAgencyListPage';
import AreaEducationAgencyConfigurationListPage from './pages/configurations/secondary/areaEducationAgency/AreaEducationAgencyConfigurationListPage';

interface AppComponentProps extends LocalizeContextProps {
  userSessionChecking: boolean;
  versionOutdated: boolean;
  versionResetAction: () => void;
  userSessionCheckAction: () => void;
  user: UserSecurity | null;
  activeLanguage: Language;
}

class App extends React.Component<AppComponentProps & RouteComponentProps<RouteObject>, RouteObject> {
  currentPath: string;

  constructor(props: AppComponentProps & RouteComponentProps<Record<string, string | undefined>>) {
    super(props);
    this.currentPath = window.location.pathname;
    History.listen((location, action) => {
      if (DEBUG) {
        console.groupCollapsed('Route Change');
        console.info('Location', location);
        console.log('Action:', action);
        console.groupEnd();
      }

      const currentPathTokens = this.currentPath ? this.currentPath.split('/').filter((s) => !!s) : [];
      const nextPathTokens = location.pathname ? location.pathname.split('/').filter((s) => !!s) : [];
      if (currentPathTokens.length > 0 && nextPathTokens.length > 0 && currentPathTokens[0] !== nextPathTokens[0]) {
        // @ts-ignore
        (document.getElementById('app-main')?.firstChild?.firstChild as any)?.scrollTo(0, 0);
      }

      this.currentPath = location.pathname;

    });

    this.configureLocalization();
  }

  configureLocalization() {
    const activeLanguage = this.props.activeLanguage;
    let activeLanguageCode = DefaultLanguage;

    if (activeLanguage !== null && activeLanguage !== undefined) {
      activeLanguageCode = activeLanguage.code;
    }

    this.props.initialize({
      languages: SupportedLanguages,
      options: {
        renderToStaticMarkup: renderToStaticMarkup,
        defaultLanguage: activeLanguageCode,
        onMissingTranslation: OnMissingTranslation,
        renderInnerHtml: true
      },
    });

    GetLanguages().forEach((x) => {
      this.props.addTranslationForLanguage(x.translation, x.code);
    });

    if (activeLanguage != null) {
      setActiveLanguage(activeLanguage.code);
    }
    else {
      setActiveLanguage(DefaultLanguage);
    }
  }

  componentDidMount() {
    this.props.userSessionCheckAction();
  }

  componentDidUpdate(prevProps: AppComponentProps) {
    if (prevProps.versionOutdated === false && this.props.versionOutdated === true) {
      Modal.confirm({
        title: 'Out of Date',
        content: 'A new version of the app was found. Do you want to update?s',
        onOk: () => {
          this.props.versionResetAction();
          location.reload();
        },
        onCancel: () => {
          this.props.versionResetAction();
        },
      });
    }
  }

  render() {
    if (this.props.userSessionChecking) {
      return (
        <Layout>
          <Spin tip='Loading...' size="large" />
        </Layout>
      );
    }
    else {
      const layout = this.props.user ? AuthenticatedLayout : PublicLayout;
      const home = this.props.user == null ? PublicLandingPage : WithAuthorization()(DashboardPage);
      const homeWidth = this.props.user == null ? 'extraExtraWide' : 'full';
      return (
        <Switch>
          {/* Home */}
          <RouteWithLayout exact={true} path={Routes.HOME} component={home} layout={layout} width={homeWidth} />

          {/* Post-Secondary */}
          <RouteWithLayout exact={true} path={Routes.POST_SECONDARY_HOME} component={PostSecondaryLandingPage} layout={layout} width='full' />

          {/* Authentication */}
          <RouteWithLayout path={Routes.LOGIN} component={LoginPage} layout={layout} width='smallish' />
          <RouteWithLayout path={Routes.REQUEST_RESET_PASSWORD} component={RequestResetPasswordPage} layout={layout} width='smallish' />
          <RouteWithLayout path={Routes.RESET_PASSWORD_LINK} component={ResetPasswordPage} layout={layout} width='smallish' />

          {/* APS General Education */}
          <RouteWithLayout exact={true} path={Routes.APS_GENERAL_EDUCATION} component={WithAuthorization([FeatureFlag.VIEW_APS_GENERAL_EDUCATION, FeatureFlag.EDIT_APS_GENERAL_EDUCATION])(APSGeneralEducationListPage)} layout={layout} />
          <RouteWithLayout path={Routes.APS_GENERAL_EDUCATION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_APS_GENERAL_EDUCATION, FeatureFlag.EDIT_APS_GENERAL_EDUCATION])(APSGeneralEducationDetailPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_APS_GENERAL_EDUCATION} component={WithAuthorization([FeatureFlag.VIEW_APS_GENERAL_EDUCATION, FeatureFlag.EDIT_APS_GENERAL_EDUCATION])(APSGeneralEducationDetailPage)} layout={layout} />

          {/* Registration */}
          <RouteWithLayout path={Routes.CONFIRM_EMAIL} component={ConfirmEmailPage} layout={layout} width='smallish' />
          <RouteWithLayout path={Routes.RESEND_CONFIRMATION_EMAIL} component={ResendConfirmationEmailPage} layout={layout} width='smallish' />

          {/* Dashboard */}
          <RouteWithLayout path={Routes.DASHBOARD} component={WithAuthorization()(DashboardPage)} layout={layout} width='full' />

          {/* Discipline */}
          <RouteWithLayout exact={true} path={Routes.DISCIPLINES} component={WithAuthorization([FeatureFlag.VIEW_DISCIPLINE, FeatureFlag.EDIT_DISCIPLINE])(DisciplineListPage)} layout={layout} />
          <RouteWithLayout path={Routes.DISCIPLINE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_DISCIPLINE, FeatureFlag.EDIT_DISCIPLINE])(DisciplineDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_DISCIPLINE} component={WithAuthorization([FeatureFlag.VIEW_DISCIPLINE, FeatureFlag.EDIT_DISCIPLINE])(DisciplineDetailsPage)} layout={layout} />

          {/* DocumentArchive */}
          <RouteWithLayout exact={true} path={Routes.DOCUMENTARCHIVES} component={WithAuthorization([FeatureFlag.VIEW_DISCIPLINE, FeatureFlag.EDIT_DISCIPLINE])(DocumentArchiveListPage)} layout={layout} width="full" />

          {/* External Institution */}
          <RouteWithLayout exact={true} path={Routes.EXTERNAL_INSTITUTIONS} component={WithAuthorization([FeatureFlag.VIEW_EXTERNAL_INSTITUTIONS, FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS])(ExternalInstitutionListPage)} layout={layout} />
          <RouteWithLayout path={Routes.EXTERNAL_INSTITUTIONS_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_EXTERNAL_INSTITUTIONS, FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS])(ExternalInstitutionDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_EXTERNAL_INSTITUTIONS} component={WithAuthorization([FeatureFlag.VIEW_EXTERNAL_INSTITUTIONS, FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS])(ExternalInstitutionDetailsPage)} layout={layout} />

          {/* Gender */}
          <RouteWithLayout exact={true} path={Routes.GENDER} component={WithAuthorization([FeatureFlag.VIEW_GENDER, FeatureFlag.EDIT_GENDER])(GenderListPage)} layout={layout} />
          <RouteWithLayout path={Routes.GENDER_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_GENDER, FeatureFlag.EDIT_GENDER])(GenderDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_GENDER} component={WithAuthorization([FeatureFlag.VIEW_GENDER, FeatureFlag.EDIT_GENDER])(GenderDetailsPage)} layout={layout} />

          {/* General Education */}
          <RouteWithLayout exact={true} path={Routes.GENERAL_EDUCATION} component={WithAuthorization([FeatureFlag.VIEW_GENERAL_EDUCATION, FeatureFlag.EDIT_GENERAL_EDUCATION])(GeneralEducationListPage)} layout={layout} />
          <RouteWithLayout path={Routes.GENERAL_EDUCATION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_GENERAL_EDUCATION, FeatureFlag.EDIT_GENERAL_EDUCATION])(GeneralEducationDetailPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_GENERAL_EDUCATION} component={WithAuthorization([FeatureFlag.VIEW_GENERAL_EDUCATION, FeatureFlag.EDIT_GENERAL_EDUCATION])(GeneralEducationDetailPage)} layout={layout} />

          {/* Announcements */}
          <RouteWithLayout exact={true} path={Routes.ANNOUNCEMENTS} component={WithAuthorization([FeatureFlag.EDIT_ANNOUNCEMENT])(AnnouncementListPage)} layout={layout} width="full" />
          <RouteWithLayout exact={true} path={Routes.ANNOUNCEMENT_DETAILS} component={WithAuthorization([FeatureFlag.EDIT_ANNOUNCEMENT])(AnnouncementDetailPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.PAST_ANNOUNCEMENTS} component={PastAnnoncementsPage} layout={layout} />
          <RouteWithLayout path={Routes.NEW_ANNOUNCEMENT} component={WithAuthorization([FeatureFlag.EDIT_ANNOUNCEMENT])(AnnouncementDetailPage)} layout={layout} />

          {/* Award Types */}
          <RouteWithLayout exact={true} path={Routes.AWARD_TYPE} component={WithAuthorization([FeatureFlag.VIEW_AWARD_TYPE, FeatureFlag.EDIT_AWARD_TYPE])(AwardTypeListPage)} layout={layout} />
          <RouteWithLayout path={Routes.AWARD_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_AWARD_TYPE, FeatureFlag.EDIT_AWARD_TYPE])(AwardTypeDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_AWARD_TYPE} component={WithAuthorization([FeatureFlag.VIEW_AWARD_TYPE, FeatureFlag.EDIT_AWARD_TYPE])(AwardTypeDetailsPage)} layout={layout} />

          {/* Category */}
          <RouteWithLayout exact={true} path={Routes.CATEGORIES} component={WithAuthorization([FeatureFlag.VIEW_CATEGORY, FeatureFlag.EDIT_CATEGORY])(CategoryListPage)} layout={layout} />
          <RouteWithLayout path={Routes.CATEGORY_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_CATEGORY, FeatureFlag.EDIT_CATEGORY])(CategoryDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_CATEGORY} component={WithAuthorization([FeatureFlag.VIEW_CATEGORY, FeatureFlag.EDIT_CATEGORY])(CategoryDetailsPage)} layout={layout} />

          {/* Change Requests */}
          <RouteWithLayout exact={true} path={Routes.CHANGE_REQUESTS} component={WithAuthorization([FeatureFlag.VIEW_CHANGE_REQUEST, FeatureFlag.EDIT_CHANGE_REQUEST])(ChangeRequestListPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.CHANGE_REQUEST_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_CHANGE_REQUEST, FeatureFlag.EDIT_CHANGE_REQUEST])(ChangeRequestDetailsPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.CHANGE_REQUEST_APPROVAL} component={WithAuthorization([FeatureFlag.APPROVE_CHANGE_REQUEST])(ChangeRequestDetailsPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.CHANGE_REQUEST_APPROVAL_REDIRECT} component={WithAuthorization([FeatureFlag.SYSTEM_ADMIN])(ChangeRequestDetailsPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.CHANGE_REQUEST_DRAFT} component={WithAuthorization([FeatureFlag.EDIT_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ChangeRequestDraftPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.CHANGE_REQUEST_DRAFT_ID} component={WithAuthorization([FeatureFlag.EDIT_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ChangeRequestDraftPage)} layout={layout} width='full' />

          {/* Change Requests - New Discipline */}
          <RouteWithLayout path={Routes.ADMINISTRATIVE_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(AdministrativeChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(AdministrativeChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(AdministrativeChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(AdministrativeChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(AdministrativeChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - New Discipline */}
          <RouteWithLayout path={Routes.NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewDisciplineChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewDisciplineChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewDisciplineChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewDisciplineChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewDisciplineChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - New Course */}
          <RouteWithLayout path={Routes.NEW_COURSE_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewCourseChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewCourseChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewCourseChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewCourseChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewCourseChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - Course Deactivation */}
          <RouteWithLayout path={Routes.COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseDeactivationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseDeactivationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseDeactivationChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseDeactivationChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseDeactivationChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - Course Modification */}
          <RouteWithLayout path={Routes.COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_3} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestStep3Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(CourseModificationChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - NoticeOfIntent */}
          <RouteWithLayout path={Routes.NEW_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_3} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep3Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_4} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep4Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_5} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep5Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_6} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep6Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_7} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep7Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_8} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestStep8Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NoticeOfIntentChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - Program Deactivation */}
          <RouteWithLayout path={Routes.PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramDeactivationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramDeactivationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramDeactivationChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramDeactivationChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramDeactivationChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - Program Modification */}
          <RouteWithLayout path={Routes.NEW_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_3} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep3Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_4} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep4Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_5} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep5Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_6} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestStep6Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestReviewPage)} width='full' layout={layout} />
          <RouteWithLayout path={Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(ProgramModificationChangeRequestSubmittedPage)} layout={layout} />

          {/* Change Requests - New Program */}
          <RouteWithLayout path={Routes.NEW_PROGRAM_CHANGE_REQUEST_ENTRY} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep1Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_2} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep2Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_3} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep3Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_4} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep4Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_5} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep5Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_6} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep6Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_7} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep7Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_8} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep8Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_9} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep9Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_10} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep10Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_11} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep11Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_12} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep12Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_13} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep13Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_14} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep14Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_15} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestStep15Page)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_REVIEW} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestReviewPage)} layout={layout} width='full' />
          <RouteWithLayout path={Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_SUBMITTED} component={WithAuthorization([FeatureFlag.CREATE_NEW_PROGRAM_REQUEST, FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])(NewProgramChangeRequestSubmitPage)} layout={layout} />

          {/* Workflow */}
          <RouteWithLayout exact={true} path={Routes.CHANGE_REQUEST_TYPE} component={WithAuthorization([FeatureFlag.EDIT_WORKFLOW])(WorkflowListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.CHANGE_REQUEST_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.EDIT_WORKFLOW])(WorkflowDetailsPage)} layout={layout} />

          {/* Configuration */}
          <RouteWithLayout exact={true} path={Routes.CONFIGURATIONS} component={WithAuthorization()(ConfigurationPage)} layout={layout} />

          {/* Contacts */}
          <RouteWithLayout exact={true} path={Routes.CONTACTS} component={WithAuthorization([FeatureFlag.EDIT_CONTACT])(ContactListPage)} layout={layout} width="full" />

          {/* Contact Types */}
          <RouteWithLayout exact={true} path={Routes.CONTACT_TYPE} component={WithAuthorization([FeatureFlag.EDIT_CONTACT_TYPE, FeatureFlag.VIEW_CONTACT_TYPE])(ContactTypeListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.CONTACT_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.EDIT_CONTACT_TYPE, FeatureFlag.VIEW_CONTACT_TYPE])(ContactTypeDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_CONTACT_TYPE} component={WithAuthorization([FeatureFlag.EDIT_CONTACT_TYPE, FeatureFlag.VIEW_CONTACT_TYPE])(ContactTypeDetailsPage)} layout={layout} />

          {/* Courses */}
          <RouteWithLayout exact={true} path={Routes.COURSES} component={CourseListPage} layout={layout} width="full" />
          <RouteWithLayout exact={true} path={Routes.COURSE_DETAILS} component={CourseDetailsPage} layout={layout} />
          <RouteWithLayout path={Routes.NEW_COURSE} component={WithAuthorization([FeatureFlag.VIEW_COURSE, FeatureFlag.EDIT_COURSE])(CourseDetailsPage)} layout={layout} />

          {/* Programs */}
          <RouteWithLayout exact={true} path={Routes.PROGRAMS} component={ProgramListPage} layout={layout} width="full" />
          <RouteWithLayout exact={true} path={Routes.PROGRAM_DETAILS} component={ProgramDetailsPage} layout={layout} width="full" />

          {/* Institution Course Block*/}
          <RouteWithLayout path={Routes.INSTITUITION_COURSE_BLOCK_DETAILS} component={InstitutionCourseBlockDetailsPage} layout={layout} />
          <RouteWithLayout path={Routes.NEW_INSTITUITION_COURSE_BLOCK} component={WithAuthorization([FeatureFlag.VIEW_INSTITUTION_COURSE_BLOCK, FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK])(InstitutionCourseBlockDetailsPage)} layout={layout} />

          {/* Credit Type */}
          <RouteWithLayout exact={true} path={Routes.CREDIT_TYPES} component={WithAuthorization([FeatureFlag.VIEW_CREDIT_TYPE, FeatureFlag.EDIT_CREDIT_TYPE])(CreditTypeListPage)} layout={layout} />
          <RouteWithLayout path={Routes.CREDIT_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_CREDIT_TYPE, FeatureFlag.EDIT_CREDIT_TYPE])(CreditTypeDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_CREDIT_TYPE} component={WithAuthorization([FeatureFlag.VIEW_CREDIT_TYPE, FeatureFlag.EDIT_CREDIT_TYPE])(CreditTypeDetailsPage)} layout={layout} />

          {/* Roles */}
          <RouteWithLayout exact={true} path={Routes.ROLES} component={WithAuthorization([FeatureFlag.VIEW_ROLE, FeatureFlag.EDIT_ROLE])(RolesListPage)} layout={layout} />
          <RouteWithLayout path={Routes.ROLE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_ROLE, FeatureFlag.EDIT_ROLE])(RoleDetailsPage)} layout={layout} width="full" />
          <RouteWithLayout path={Routes.NEW_ROLE} component={WithAuthorization([FeatureFlag.VIEW_ROLE, FeatureFlag.EDIT_ROLE])(RoleDetailsPage)} layout={layout} />

          {/* Institutions */}
          <RouteWithLayout exact={true} path={Routes.INSTITUTIONS} component={WithAuthorization([FeatureFlag.VIEW_INSTITUTION, FeatureFlag.EDIT_INSTITUTION])(InstitutionListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.INSTITUTION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_INSTITUTION, FeatureFlag.EDIT_INSTITUTION])(InstitutionDetailPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_INSTITUTION} component={WithAuthorization([FeatureFlag.VIEW_INSTITUTION, FeatureFlag.EDIT_INSTITUTION])(InstitutionDetailPage)} layout={layout} />

          {/* Institution Courses */}
          <RouteWithLayout path={Routes.COURSE_INSTITUTION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_COURSE, FeatureFlag.EDIT_COURSE])(InstitutionCourseDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.INSTITUTION_COURSE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_COURSE, FeatureFlag.EDIT_COURSE])(InstitutionCourseDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.SINGLE_INSTITUTION_COURSE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_COURSE, FeatureFlag.EDIT_COURSE])(InstitutionCourseDetailsPage)} layout={layout} />

          {/* Institutions Users */}
          <RouteWithLayout path={Routes.USER_INSTITUTION_DETAILS} component={WithAuthorization([FeatureFlag.MANAGE_INSTITUTION_USER])(InstitutionUserDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.INSTITUTION_USER_DETAILS} component={WithAuthorization([FeatureFlag.MANAGE_INSTITUTION_USER])(InstitutionUserDetailsPage)} layout={layout} />

          {/* Public */}
          <RouteWithLayout path={Routes.CONSULTANTS} component={publicConsultantsSearchPage} layout={layout} />

          {/* Racial/Ethnic Groups */}
          <RouteWithLayout exact={true} path={Routes.RACIAL_ETHNIC_GROUPS} component={WithAuthorization([FeatureFlag.VIEW_RACIAL_EHTNIC_GROUPS, FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS])(RacialEthnicGroupListPage)} layout={layout} />
          <RouteWithLayout path={Routes.RACIAL_ETHNIC_GROUP_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_RACIAL_EHTNIC_GROUPS, FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS])(RacialEthnicGroupDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_RACIAL_ETHNIC_GROUP} component={WithAuthorization([FeatureFlag.VIEW_RACIAL_EHTNIC_GROUPS, FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS])(RacialEthnicGroupDetailsPage)} layout={layout} />

          {/* Resources */}
          <RouteWithLayout path={Routes.ADMIN_RESOURCES} component={WithAuthorization([FeatureFlag.EDIT_RESOURCE])(AdminResourcePage)} layout={layout} />
          <RouteWithLayout path={Routes.RESOURCES} component={PublicResourcePage} layout={layout} />

          {/* Downloads Section */}
          <RouteWithLayout path={Routes.DOWNLOADS_SECTION} component={DownloadsPage} layout={layout} />

          {/* Terms */}
          <RouteWithLayout exact={true} path={Routes.TERMS} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(TermListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.TERM_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(TermDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_TERM} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(TermDetailsPage)} layout={layout} />

          {/* Third Party Credential Type */}
          <RouteWithLayout exact={true} path={Routes.THIRD_PARTY_CREDENTIAL_TYPES} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(ThirdPartyCredentialTypeListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.THIRD_PARTY_CREDENTIAL_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(ThirdPartyCredentialTypeDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_THIRD_PARTY_CREDENTIAL_TYPE} component={WithAuthorization([FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM])(ThirdPartyCredentialTypeDetailsPage)} layout={layout} />

          {/* Users */}
          <RouteWithLayout exact={true} path={Routes.USERS} component={WithAuthorization([FeatureFlag.VIEW_USER, FeatureFlag.EDIT_USER, FeatureFlag.MANAGE_INSTITUTION_USER])(UsersListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.USER_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_USER, FeatureFlag.EDIT_USER])(UserDetailsPage)} layout={layout} width='wide' />

          {/* User Settings */}
          <RouteWithLayout exact={true} path={Routes.USER_SETTINGS} component={WithAuthorization()(UserSettingsPage)} layout={layout} />

          {/* Work Items */}
          <RouteWithLayout exact={true} path={Routes.WORK_ITEMS} component={WithAuthorization()(WorkItemListPage)} layout={layout} width="full" />

          {/* Cips */}
          <RouteWithLayout exact={true} path={Routes.CIPS} component={WithAuthorization([FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER])(CipNumberListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.CIP_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER])(CipNumberDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_CIP} component={WithAuthorization([FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER])(CipNumberDetailsPage)} layout={layout} />

          {/* Program Classification */}
          <RouteWithLayout exact={true} path={Routes.PROGRAM_CLASSIFICATION} component={WithAuthorization([FeatureFlag.VIEW_PROGRAM_CLASSIFICATION, FeatureFlag.EDIT_PROGRAM_CLASSIFICATION])(ProgramClassificationListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.PROGRAM_CLASSIFICATION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_PROGRAM_CLASSIFICATION, FeatureFlag.EDIT_PROGRAM_CLASSIFICATION])(ProgramClassificationDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_PROGRAM_CLASSIFICATION} component={WithAuthorization([FeatureFlag.VIEW_PROGRAM_CLASSIFICATION, FeatureFlag.EDIT_PROGRAM_CLASSIFICATION])(ProgramClassificationDetailsPage)} layout={layout} />

          {/* Notice Of Intent Change Request WorkForce Link*/}
          <RouteWithLayout exact={true} path={Routes.NOTICE_OF_INTENT_CHANGE_REQUEST_WORKFORCE_LINK} component={WithAuthorization([FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER])(NoticeOfIntentChangeRequestWorkForceLinkListPage)} layout={layout} />

          {/* Instructional Levels */}
          <RouteWithLayout exact={true} path={Routes.INSTRUCTIONAL_LEVEL} component={WithAuthorization([FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])(InstructionalLevelListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.INSTRUCTIONAL_LEVEL_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])(InstructionalLevelDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_INSTRUCTIONAL_LEVEL} component={WithAuthorization([FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])(InstructionalLevelDetailsPage)} layout={layout} />

          {/* Iowa Variations */}
          <RouteWithLayout exact={true} path={Routes.IOWA_VARIATION} component={WithAuthorization([FeatureFlag.VIEW_IOWA_VARIATION, FeatureFlag.EDIT_IOWA_VARIATION])(IowaVariationListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.IOWA_VARIATION_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_IOWA_VARIATION, FeatureFlag.EDIT_IOWA_VARIATION])(IowaVariationDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_IOWA_VARIATION} component={WithAuthorization([FeatureFlag.VIEW_IOWA_VARIATION, FeatureFlag.EDIT_IOWA_VARIATION])(IowaVariationDetailsPage)} layout={layout} />

          {/* Object Purpose */}
          <RouteWithLayout exact={true} path={Routes.OBJECT_PURPOSE} component={WithAuthorization([FeatureFlag.VIEW_OBJECT_PURPOSE, FeatureFlag.EDIT_OBJECT_PURPOSE])(ObjectPurposeListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.OBJECT_PURPOSE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_OBJECT_PURPOSE, FeatureFlag.EDIT_OBJECT_PURPOSE])(ObjectPurposeDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_OBJECT_PURPOSE} component={WithAuthorization([FeatureFlag.VIEW_OBJECT_PURPOSE, FeatureFlag.EDIT_OBJECT_PURPOSE])(ObjectPurposeDetailsPage)} layout={layout} />

          {/* Special Emphases */}
          <RouteWithLayout exact={true} path={Routes.SPECIAL_EMPHASIS} component={WithAuthorization([FeatureFlag.VIEW_SPECIAL_EMPHASIS, FeatureFlag.EDIT_SPECIAL_EMPHASIS])(SpecialEmphasisListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.SPECIAL_EMPHASIS_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_SPECIAL_EMPHASIS, FeatureFlag.EDIT_SPECIAL_EMPHASIS])(SpecialEmphasisDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_SPECIAL_EMPHASIS} component={WithAuthorization([FeatureFlag.VIEW_SPECIAL_EMPHASIS, FeatureFlag.EDIT_SPECIAL_EMPHASIS])(SpecialEmphasisDetailsPage)} layout={layout} />

          {/* Transfer Major Lacts Categories */}
          <RouteWithLayout exact={true} path={Routes.TRANSFER_MAJOR_LACTS_CATEGORY} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_LACTS_CATEGORY, FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY])(TransferMajorLactsCategoryListPage)} layout={layout} />
          <RouteWithLayout path={Routes.TRANSFER_MAJOR_LACTS_CATEGORY_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_LACTS_CATEGORY, FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY])(TransferMajorLactsCategoryDetialsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_TRANSFER_MAJOR_LACTS_CATEGORY} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_LACTS_CATEGORY, FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY])(TransferMajorLactsCategoryDetialsPage)} layout={layout} />

          {/* Transfer Major Course */}
          <RouteWithLayout exact={true} path={Routes.TRANSFER_MAJOR_COURSE_BLOCK} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_COURSE_BLOCKS, FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS])(TransferMajorCourseBlockListPage)} layout={layout} />
          <RouteWithLayout path={Routes.TRANSFER_MAJOR_COURSE_BLOCK_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_COURSE_BLOCKS, FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS])(TransferMajorCourseBlockDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_TRANSFER_MAJOR_COURSE_BLOCK} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_COURSE_BLOCKS, FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS])(TransferMajorCourseBlockDetailsPage)} layout={layout} />

          {/* Transfer Major Discipline Framework Element */}
          <RouteWithLayout exact={true} path={Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS, FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS])(TransferMajorDisciplineFrameworkElementListPage)} layout={layout} />
          <RouteWithLayout path={Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS, FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS])(TransferMajorDisciplineFrameworkElementDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS, FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS])(TransferMajorDisciplineFrameworkElementDetailsPage)} layout={layout} />

          {/* Transfer Major General Education Type */}
          <RouteWithLayout exact={true} path={Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE, FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE])(TransferMajorGeneralEducationTypeListPage)} layout={layout} />
          <RouteWithLayout path={Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE, FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE])(TransferMajorGeneralEducationTypeDetailsPage)} layout={layout} />
          <RouteWithLayout path={Routes.NEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE} component={WithAuthorization([FeatureFlag.VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE, FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE])(TransferMajorGeneralEducationTypeDetailsPage)} layout={layout} />

          {/* Type Of Program */}
          <RouteWithLayout exact={true} path={Routes.TYPE_OF_PROGRAM} component={WithAuthorization([FeatureFlag.VIEW_TYPE_OF_PROGRAM, FeatureFlag.EDIT_TYPE_OF_PROGRAM])(TypeOfProgramListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.TYPE_OF_PROGRAM_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_TYPE_OF_PROGRAM, FeatureFlag.EDIT_TYPE_OF_PROGRAM])(TypeOfProgramDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_TYPE_OF_PROGRAM} component={WithAuthorization([FeatureFlag.VIEW_TYPE_OF_PROGRAM, FeatureFlag.EDIT_TYPE_OF_PROGRAM])(TypeOfProgramDetailsPage)} layout={layout} />

          {/* Work Based Learning Type */}
          <RouteWithLayout exact={true} path={Routes.WORK_BASED_LEARNING_TYPE} component={WithAuthorization([FeatureFlag.VIEW_WORK_BASED_LEARNING, FeatureFlag.EDIT_WORK_BASED_LEARNING])(WorkBasedLearningTypeListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.WORK_BASED_LEARNING_DETAILS} component={WithAuthorization([FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])(WorkBasedLearningTypeDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_WORK_BASED_LEARNING_TYPE} component={WithAuthorization([FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])(WorkBasedLearningTypeDetailsPage)} layout={layout} />

          {/* Secondary */}
          <RouteWithLayout exact={true} path={Routes.SECONDARY_HOME} component={(SecondaryLandingPage)} layout={layout} width='full' />
          {/* Self Studies */}
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_LIST} component={(SelfStudiesListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_ACADEMIC_YEAR_LIST} component={(SelfStudiesListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_DETAILS} component={(SelfStudyDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_DISPLAY} component={(SelfStudyDetailDisplayPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_RESPONSE_DISPLAY} component={(SelfStudyResponseDisplayPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEARS} component={(SelfStudiesPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.COMPLETE_SELF_STUDIES} component={(CompleteSelfStudyPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_CONFIGURATION} component={(SelfStudyConfigurationLandingPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_CONFIGURATION_FROM_LIST} component={(SelfStudyConfigurationLandingPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD} component={(AcademicYearDashboardPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_DASHBOARD_FROM_LIST} component={(AcademicYearDashboardPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.NEW_ACADEMIC_YEAR} component={(AcademicYearPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_ACADEMIC_YEAR_FROM_LIST} component={(AcademicYearPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.ALL_ACADEMIC_YEARS} component={(AcademicYearListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SELF_STUDY_RANKING_REPORT} component={(SelfStudyRankingReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_PROGRAM_CONSULTANT_COMMENT_REPORT} component={(ProgramConsultantCommentPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SELF_STUDIES_REVIEW} component={(SelfStudyReviewPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_CONFIGURATION} component={(AcademicYearConfigurationPage)} layout={layout} />

          {/*Regions*/}
          <RouteWithLayout exact={true} path={Routes.REGIONAL_PLANNING_PARTNERS} component={WithAuthorization([FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER])(RegionalPlanningPartnerListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.REGIONAL_PLANNING_PARTNER_DETAIL} component={WithAuthorization([FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER])(RegionalPlanningPartnerDetailsPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.NEW_REGIONAL_PLANNING_PARTNER_DETAIL} component={WithAuthorization([FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER])(RegionalPlanningPartnerDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_REGIONAL_PLANNING_PARTNER} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewRegionalPlanningPartnerListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_REGIONAL_PLANNING_PARTNER_CONFIGURATION} component={WithAuthorization([FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER])(AcademicYearRegionalPlanningPartnerConfigurationPage)} layout={layout} width='extraExtraWide' />

          {/*Districts*/}
          <RouteWithLayout exact={true} path={Routes.PUBLIC_DISTRICTS} component={(DistrictListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.DISTRICTS} component={(DistrictListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.DISTRICTS_ACADEMIC_YEAR_LIST} component={(DistrictListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.ALL_DISTRICTS} component={(DistrictListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.NEW_DISTRICT} component={(DistrictDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.DISTRICT_DETAILS} component={(DistrictDetailsPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_DISTRICTS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewDistrictListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_DISTRICT_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearDistrictConfigurationPage)} layout={layout} width='full' />

          {/*District Contacts*/}
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_DISTRICT_CONTACTS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewDistrictContactListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_DISTRICT_CONTACT_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearDistrictContactConfigurationPage)} layout={layout} width='full' />

          {/*Area Education Agency*/}
          <RouteWithLayout exact={true} path={Routes.AREA_EDUCATION_AGENCIES} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AreaEducationAgencyListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.ALL_AREA_EDUCATION_AGENCIES} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AreaEducationAgencyListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.NEW_AREA_EDUCATION_AGENCY} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AreaEducationAgencyDetailPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.AREA_EDUCATION_AGENCY_DETAILS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AreaEducationAgencyDetailPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.REVIEW_AREA_EDUCATION_AGENCY} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(ReviewAreaEducationAgencyAcdemicYearPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_AREA_EDUCATION_AGENCY} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewAreaEducationAgencyListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.AREA_EDUCATION_AGENCIES_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AreaEducationAgencyConfigurationListPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_AREA_EDUCATION_AGENCIES_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearAreaEducationAgencyConfigurationPage)} layout={layout} />

          {/*Federal Clusters*/}
          <RouteWithLayout exact={true} path={Routes.FEDERAL_CLUSTERS} component={(FederalClusterListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ALL_FEDERAL_CLUSTERS} component={(FederalClusterListPage)} layout={layout} width='full' />
          {/* <RouteWithLayout exact={true} path={Routes.NEW_FEDERAL_CLUSTER} component={(FederalClusterDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.FEDERAL_CLUSTER_DETAILS} component={(FederalClusterDetailsPage)} layout={layout} /> */}
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_FEDERAL_CLUSTERS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewFederalClusterListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.FEDERAL_CLUSTERS_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(FederalClustersConfigurationListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_FEDERAL_CLUSTERS_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearFederalClusterConfigurationPage)} layout={layout} width='full' />

          {/*Service Areas*/}
          <RouteWithLayout exact={true} path={Routes.SERVICE_AREAS} component={(ServiceAreaListPage)} layout={layout} width='extraWide' />
          <RouteWithLayout exact={true} path={Routes.ALL_SERVICE_AREAS} component={(ServiceAreaListPage)} layout={layout} width='full' />
          {/* <RouteWithLayout exact={true} path={Routes.NEW_SERVICE_AREA} component={(ServiceAreaDetailsPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.SERVICE_AREA_DETAILS} component={(ServiceAreaDetailsPage)} layout={layout} /> */}
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SERVICE_AREAS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewServiceAreaListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SERVICE_AREA_CONFIGURATION} component={(ServiceAreaConfigurationListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SERVICE_AREAS_CONFIGURATION} component={(AcademicYearServiceAreasConfigurationPage)} layout={layout} width='full' />

          {/*Secondary Program*/}
          <RouteWithLayout exact={true} path={Routes.PUBLIC_PROGRAMS} component={(SecondaryProgramListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_PROGRAMS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryProgramListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_PROGRAMS_ACADEMIC_YEAR_LIST} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryProgramListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.ALL_SECONDARY_PROGRAMS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryProgramListPage)} layout={layout} width='extraExtraWide' />
          <RouteWithLayout exact={true} path={Routes.NEW_SECONDARY_PROGRAM} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryProgramDetailPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_PROGRAM_DETAILS} component={(SecondaryProgramDetailPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SECONDARY_PROGRAMS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewSecondaryProgramListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_CONFIGURATION} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(AcademicYearSecondaryProgramConfigurationPage)} layout={layout} width='extraExtraWide' />

          {/*Secondary Program Type*/}
          <RouteWithLayout exact={true} path={Routes.SECONDARY_PROGRAM_TYPES} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM_SCENARIO])(SecondaryProgramScenarioListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ALL_SECONDARY_PROGRAM_TYPES} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM_SCENARIO])(SecondaryProgramScenarioListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.NEW_SECONDARY_PROGRAM_TYPE} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM_SCENARIO])(SecondaryProgramScenarioDetailsPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_PROGRAM_TYPE_DETAILS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM_SCENARIO])(SecondaryProgramScenarioDetailsPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SECONDARY_PROGRAM_TYPES} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewSecondaryProgramScenarioListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SCENARIO_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(ScenarioConfigurationListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SCENARIOS_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearScenarioConfigurationPage)} layout={layout} width='full' />

          {/*Secondary Program Course*/}
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SECONDARY_PROGRAM_COURSES} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewSecondaryProgramCourseListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_COURSES_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearProgramCoursesConfigurationPage)} layout={layout} width='full' />

          {/*Secondary Course*/}
          <RouteWithLayout exact={true} path={Routes.SECONDARY_COURSES} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_COURSE])(SecondaryCourseListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_COURSES_ACADEMIC_YEAR_LIST} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_COURSE])(SecondaryCourseListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ALL_SECONDARY_COURSES} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_COURSE])(SecondaryCourseListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.NEW_SECONDARY_COURSE} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_COURSE])(SecondaryCourseDetailPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_COURSE_DETAILS} component={(SecondaryCourseDetailPage)} layout={layout} />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SECONDARY_COURSES} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewSecondaryCourseListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SECONDARY_COURSES_CONFIGURATION} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_COURSE])(AcademicYearSecondaryCoursesConfigurationPage)} layout={layout} width='full' />

          {/*Secondary CIP Number*/}
          <RouteWithLayout exact={true} path={Routes.SECONDARY_CIP_NUMBERS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryCipNumberListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ALL_SECONDARY_CIP_NUMBERS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryCipNumberListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.NEW_SECONDARY_CIP_NUMBER} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryCipNumberDetailPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_CIP_NUMBER_DETAILS} component={WithAuthorization([FeatureFlag.EDIT_SECONDARY_PROGRAM])(SecondaryCipNumberDetailPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.DATA_REVIEW_SECONDARY_CIP_NUMBERS} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(DataReviewSecondaryCipNumberListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.SECONDARY_CIP_NUMBERS_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(SecondaryCipNumberConfigurationListPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SECONDARY_CIP_NUMBER_CONFIGURATION} component={WithAuthorization([FeatureFlag.CONFIG_ACADEMIC_YEAR])(AcademicYearSecondaryCipNumberConfigurationPage)} layout={layout} width='full' />

          {/*Secondary Reports*/}
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_CONTACTS_REPORT} component={(ContactsReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_CAREER_ACADEMY_REPORT} component={(CareerAcademyReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_ACTION_PLAN_REPORT} component={(ActionPlanReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SELF_STUDY_AGGREGATE_REPORT} component={(SelfStudyAggregateReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_SCENARIO_REPORT} component={(ScenarioReportPage)} layout={layout} width='full' />
          <RouteWithLayout exact={true} path={Routes.ACADEMIC_YEAR_PROGRAM_MASTER_REPORT} component={(ProgramMasterReportPage)} layout={layout} width='full' />

          {/* Error */}
          <Route component={RedirectAs404} />
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    userSessionCheckAction: () => dispatch(UserSessionActions.check()),
    versionResetAction: () => dispatch(VersionActions.reset()),
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    activeLanguage: getActiveLanguage(state.localize),
    userSessionChecking: state.UserSession.Checking,
    versionOutdated: state.Version.Outdated,
    user: state.UserSession.Value,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(App)));
