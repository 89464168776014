import { CloseCircleFilled, DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, FormItemProps, message, Modal, Select, Skeleton, Space, Switch, Tabs, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import FormList, { FormListFieldData } from 'antd/lib/form/FormList';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SecondaryProgramScenarioApiService from '../../../api/SecondaryProgramScenarioApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import Routes from '../../../config/Routes';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import * as GetSecondaryProgramScenariosHandler from '../../../handlerModels/GetSecondaryProgramScenariosHandler';
import * as GetSelfStudyConfigurationHandler from '../../../handlerModels/GetSelfStudyConfigurationHandler';
import * as SaveSelfStudyTemplateHandler from '../../../handlerModels/SaveSelfStudyTemplateHandler';
import SecondaryProgramScenarioDTO from '../../../models/SecondaryProgramScenarioDTO';
import SelfStudyResponseTemplateConsultantReviewQuestionDTO from '../../../models/SelfStudyResponseTemplateConsultantReviewQuestionDTO';
import SelfStudySectionComponentTemplateDTO from '../../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import SelfStudyTemplateDTO from '../../../models/SelfStudyTemplateDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import StringUtil from '../../../utils/StringUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import { DraggableTab } from '../../general/DraggableTab';
import Dropdown from '../../inputs/Dropdown';
import ReadableNumberInput from '../../inputs/ReadableNumberInput';
import SelfStudySectionForm from './SelfStudySectionForm';

interface SelfStudyConfigurationFormProps extends BaseFormProps {
  academicYear: number;
  readonly?: boolean;
}

function SelfStudyConfigurationForm(props: SelfStudyConfigurationFormProps) {
  const [activeKey, setActiveKey] = useState('0');
  const [preview, setPreview] = useState(false);
  const [selfStudyTemplate, setSelfStudyTemplate] = useState<SelfStudyTemplateDTO | null>(null);
  const [, setIsAltered] = useState(false);
  const [submitted, setIsSubmitted] = useState(false);
  const [submitting, setIsSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: Validator[]; } | null | undefined>(null);
  const [, setErrorMessage] = useState<string | null>(null);
  const [, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false); //  fix loading
  const [_formRef] = useForm();
  const [secondaryProgramScenarioId, setSecondaryProgramScenarioId] = useState<Guid | null>(null);
  const [secondaryProgramScenarios, setSecondaryProgramScenarios] = useState([] as SecondaryProgramScenarioDTO[]);

  const getSelfStudyFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SelfStudyTemplateDTO.minimumCareerAcademyScore, {
        required: true,
        name: SelfStudyTemplateDTO.minimumCareerAcademyScore,
        label: 'Minimum Career Academy Score',
        rules: [ValidationRuleUtil.required()]
      });
  }

  useEffect(() => {
    setLoading(true);
    const loaders = []
    if (props.academicYear != 0) {
      loaders.push(loadSecondaryProgramScenarios());
      loaders.push(SelfStudyTemplateApiService.getSelfStudy(props.academicYear)
        .then((results: GetSelfStudyConfigurationHandler.Result) => {
          if (results.selfStudy) {
            setSelfStudyTemplate(results.selfStudy);
            resetForm();
          }
          else {
            setSelfStudyTemplate(SelfStudyTemplateDTO.create(
              {
                academicYear: props.academicYear,
                selfStudySectionTemplates: [
                  SelfStudySectionTemplateDTO.create({
                    selfStudySectionScenarios: [],
                    selfStudySectionTypeId: null,
                    title: '',
                    selfStudySectionComponentTemplates: [
                      SelfStudySectionComponentTemplateDTO.create(
                        {
                          selfStudySectionComponentScenarios: [],
                          selfStudySectionComponentTypeId: null,
                          selfStudyResponseTemplateConsultantReviewQuestions: [SelfStudyResponseTemplateConsultantReviewQuestionDTO.create()]
                        }
                      )
                    ]
                  })
                ]
              }
            ));
          }
        }));
      Promise.all(loaders).then(() => {
        setLoading(false);
        resetForm();
      });
    }
  }, [props.academicYear]
  )

  const loadSecondaryProgramScenarios = () => {
    const request = GetSecondaryProgramScenariosHandler.Request.create({
      academicYearId: props.academicYear ?? 0
    });

    return SecondaryProgramScenarioApiService.getSecondaryProgramScenarios(request)
      .then((results: GetSecondaryProgramScenariosHandler.Result) => {
        if (results) {
          setSecondaryProgramScenarios(results.secondaryProgramScenario ?? []);
          setSecondaryProgramScenarioId((results.secondaryProgramScenario ?? [])[0].id)
        }

      })
      .catch(() => {
        setError(true);
      });
  }

  const resetForm = () => {
    _formRef?.resetFields();
    setIsAltered(false)
  }

  const onTabChange = (newActiveKey: any) => {
    setActiveKey(newActiveKey);
  }

  const handleSubmit = (finalize: boolean) => {
    setIsSubmitting(true);

    const formSelfStudy = SelfStudyTemplateDTO.create(_formRef ? (_formRef as any).getFieldsValue() : null) as any;
    formSelfStudy.id = selfStudyTemplate?.id ?? Guid.Empty();

    const previousGoalSection = formSelfStudy.selfStudySectionTemplates?.find((x: SelfStudySectionTemplateDTO) =>
      x.selfStudySectionComponentTemplates?.find(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.PREVIOUSGOALS));
    const previousGoaltemplate = previousGoalSection?.selfStudySectionComponentTemplates;
    if (previousGoalSection) {
      previousGoalSection.selfStudySectionComponentTemplates = previousGoaltemplate?.filter((x: any) => x?.section !== null || x?.primaryText || x?.selfStudySectionComponentTypeId || x?.selfStudySectionTemplateSecondaryProgramScenarios);
    }

    const newGoalSection = formSelfStudy.selfStudySectionTemplates?.find((x: SelfStudySectionTemplateDTO) =>
      x.selfStudySectionComponentTemplates?.find(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.NEWGOALS));
    const newGoalTemplate = newGoalSection?.selfStudySectionComponentTemplates;
    if (newGoalSection) {
      newGoalSection.selfStudySectionComponentTemplates = newGoalTemplate?.filter((x: any) => x?.measureOfSuccess !== null || x?.primaryText || x?.selfStudySectionComponentTypeId || x?.selfStudySectionTemplateSecondaryProgramScenarios);
    }
    const request = SaveSelfStudyTemplateHandler.Request.create({
      academicYear: props.academicYear,
      selfStudy: formSelfStudy,
      finalize: finalize
    });

    SelfStudyTemplateApiService.saveSelfStudyTemplate(request)
      .then((result: SaveSelfStudyTemplateHandler.Result) => {
        setIsSubmitted(true);
        if (result?.succeeded) {
          if (request.finalize) {
            message.success('Program Review Finalized');
            HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: request.academicYear }, {}));
          }
          else {
            setSelfStudyTemplate(result.selfStudy);
            message.success('Saved');
            setFieldErrors(result.fieldErrors);
            setIsAltered(false);
            resetForm();
          }
        }
        else {
          message.error('Program Review could not be saved');
          setFieldErrors(result.fieldErrors);
          setErrorMessage(result?.errors.join('\n'));
          setError(!result?.succeeded);
          setIsSubmitted(true);
        }
      })
      .catch(() => {
        message.error('Program Review could not be saved.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const handleOnChange = () => {
    setIsAltered(true);
  }

  const handleTitleChange = (sectionIndex: number, title: string) => {
    console.log(title);
  }

  const onPreviewSwitch = () => {
    if (preview) {
      const formSelfStudy = SelfStudyTemplateDTO.create(_formRef ? (_formRef as any).getFieldsValue() : null) as any;
      formSelfStudy.id = selfStudyTemplate?.id ?? Guid.Empty();
      const previousGoalSection = formSelfStudy.selfStudySectionTemplates?.find((x: SelfStudySectionTemplateDTO) =>
        x?.selfStudySectionComponentTemplates?.find(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.PREVIOUSGOALS));
      const previousGoaltemplate = previousGoalSection?.selfStudySectionComponentTemplates;
      if (previousGoalSection) {
        previousGoalSection.selfStudySectionComponentTemplates = previousGoaltemplate?.filter((x: any) => x?.section !== null || x?.primaryText || x?.selfStudySectionComponentTypeId || x?.selfStudySectionTemplateSecondaryProgramScenarios);
      }

      const newGoalSection = formSelfStudy.selfStudySectionTemplates?.find((x: SelfStudySectionTemplateDTO) =>
        x?.selfStudySectionComponentTemplates?.find(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.NEWGOALS));
      const newGoalTemplate = newGoalSection?.selfStudySectionComponentTemplates;
      if (newGoalSection) {
        newGoalSection.selfStudySectionComponentTemplates = newGoalTemplate.filter((x: any) => x?.measureOfSuccess !== null || x?.primaryText || x?.selfStudySectionComponentTypeId || x?.selfStudySectionTemplateSecondaryProgramScenarios);
      }

      _formRef.setFieldsValue({ selfStudySectionTemplates: formSelfStudy.selfStudySectionTemplates });

    }
    setPreview(!preview);
  }

  const handleOnSelect = (x: any) => {
    setSecondaryProgramScenarioId(x);
  }

  const handleOnClear = () => {
    setSecondaryProgramScenarioId(null);
  }

  const handleAddNewSection = (add: any) => {
    add(
      SelfStudySectionTemplateDTO.create({
        selfStudySectionTypeId: null,
        title: 'New Section',
        selfStudySectionComponentTemplates: [
          SelfStudySectionComponentTemplateDTO.create(
            {
              selfStudySectionComponentTypeId: null
            }
          )
        ]
      })
    )

    const form = _formRef.getFieldsValue();
    const numberOfTemplates = form.selfStudySectionTemplates?.length ?? 0;
    setActiveKey((numberOfTemplates - 1).toString());
  }

  const moveTabNode = (dragKey: React.Key, hoverKey: React.Key) => {
    const formSection = SelfStudyTemplateDTO.create(_formRef ? (_formRef as any).getFieldsValue() : null) as any;
    const sections = formSection.selfStudySectionTemplates as SelfStudySectionTemplateDTO[];
    const [removed] = sections?.splice(Number.parseInt(dragKey.toString()), 1) ?? [];
    sections?.splice(Number.parseInt(hoverKey.toString()), 0, removed);
    formSection.id = selfStudyTemplate?.id;
    setSelfStudyTemplate(formSection);
    setActiveKey(hoverKey.toString());
    resetForm();
  };

  const onEdit = (targetKey: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove', add: any) => {
    if (action === 'add') {
      handleAddNewSection(add);
    }
    else if (action === 'remove') {
      Modal.confirm({
        title: 'Remove Section',
        content: 'Are you sure you want to remove this Section?',
        onOk: () => onRemoveSection(targetKey),
        okText: 'Remove',
        width: 500
      });
    }
  }

  const onRemoveSection = (sectionIndex: any) => {
    const index = Number.parseInt(sectionIndex);
    const formSection = SelfStudyTemplateDTO.create(_formRef ? (_formRef as any).getFieldsValue() : null) as any;
    const sections = formSection.selfStudySectionTemplates as SelfStudySectionTemplateDTO[];
    sections.remove(sections[index]);
    formSection.id = selfStudyTemplate?.id;
    setSelfStudyTemplate(formSection);
    setActiveKey(((index - 1) >= 0 ? (index - 1) : index).toString());
    _formRef.setFieldsValue({ selfStudySectionTemplates: formSection.selfStudySectionTemplates })
  }

  const renderSections = () => {
    return (<>
      <Typography.Title level={5} >Sections</Typography.Title>
      <FormList name={SelfStudyTemplateDTO.selfStudySectionTemplates}>
        {
          (sections, { add, }) => {
            return (
              <DraggableTab
                addIcon={<><PlusOutlined /> Add Section</>}
                activeKey={activeKey}
                onChange={onTabChange}
                tabPosition='left'
                type="editable-card"
                moveTabNode={moveTabNode}
                onEdit={(targetKey, action) => onEdit(targetKey, action, add)}>
                {sections.map((section, index) => renderSection(section, index))}
              </DraggableTab>
            );
          }
        }
      </FormList >
    </>);
  }

  const renderSelfStudy = () => {
    return (
      <>
        <Typography.Title level={5} >Settings</Typography.Title>
        <FormItem
          {...getSelfStudyFormItems().get(SelfStudyTemplateDTO.minimumCareerAcademyScore)}
          {...ValidationUtil.getValidation(SelfStudyTemplateDTO.minimumCareerAcademyScore, props.fieldErrors, submitted)} >
          <ReadableNumberInput />
        </FormItem>
      </>
    );
  }

  const renderSection = (section: FormListFieldData, sectionIndex: number) => {

    let selfStudySection: SelfStudySectionTemplateDTO = SelfStudySectionTemplateDTO.create();

    const form = _formRef.getFieldsValue();
    const selfStudySectionScenarios = (form?.selfStudySectionTemplates ?? [])[section.key];

    if (selfStudyTemplate?.selfStudySectionTemplates) {
      selfStudySection = selfStudyTemplate.selfStudySectionTemplates[section.key];
    }
    if (!selfStudySection && form?.selfStudySectionTemplates) {
      selfStudySection = form?.selfStudySectionTemplates[section.key];
    }

    const sectionError = fieldErrors ? fieldErrors[SelfStudyTemplateDTO.selfStudySectionTemplates + sectionIndex] ?? false : false;
    const title = sectionError ? <span><CloseCircleFilled color={'red'} /> {selfStudySection?.title}</span> : StringUtil.shorten(selfStudySection?.title, 40, true);

    return (
      <Tabs.TabPane
        tab={<><MenuOutlined /> {title}</>}
        key={sectionIndex}
        className="customDraggableTab"
        closeIcon={<DeleteOutlined />}
      >{!secondaryProgramScenarioId || !preview || (selfStudySectionScenarios.selfStudySectionTemplateSecondaryProgramScenarios.length == 0 || selfStudySectionScenarios.selfStudySectionTemplateSecondaryProgramScenarios.findIndex((x: any) => x == secondaryProgramScenarioId) > -1) ?
        <SelfStudySectionForm
          form={_formRef}
          preview={preview}
          key={sectionIndex}
          section={selfStudySection}
          sectionIndex={sectionIndex}
          fieldErrors={fieldErrors}
          submitted={submitted}
          academicYear={props.academicYear}
          secondaryProgramScenarioId={secondaryProgramScenarioId}
          resetForm={resetForm}
          onAltered={handleOnChange}
          onTitleChange={handleTitleChange} />
        :
        null
        }
      </Tabs.TabPane>
    );
  }

  const renderActions = () => {
    if (!props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <Button type='primary' loading={submitting} onClick={() => handleSubmit(true)} >Save and Finalize</Button>
          <Button type='default' loading={submitting} onClick={() => handleSubmit(false)} >Save</Button>
        </Space>
      );
    }
  }

  const renderSecondaryProgramScenarios = (SecondaryProgramScenario: SecondaryProgramScenarioDTO) => {
    if (SecondaryProgramScenario.id) {
      return <Select.Option title={SecondaryProgramScenario.title ?? ''} key={SecondaryProgramScenario.id ?? Guid.Empty()} value={SecondaryProgramScenario.id ?? Guid.Empty()}>{SecondaryProgramScenario.title}</Select.Option>
    }
  }

  const renderPreviewOptions = () => {
    return (
      <Space >
        <label>Preview: </label>
        <Switch title='preview' id='preview' onChange={onPreviewSwitch} />
        {
          preview ?
            <Dropdown
              defaultValue={secondaryProgramScenarioId}
              dropdownMatchSelectWidth={false}
              allowClear
              onClear={handleOnClear}
              onSelect={handleOnSelect}
            >
              {secondaryProgramScenarios?.map(x => renderSecondaryProgramScenarios(x))}
            </Dropdown> :
            null
        }
      </Space >
    );
  }

  if (loading && secondaryProgramScenarioId) {
    return <Skeleton active={true} />
  }
  else if (!loading && selfStudyTemplate) {
    return (
      <Space direction='vertical'>
        <Form
          form={_formRef}
          layout="vertical"
          initialValues={selfStudyTemplate}
          onChange={handleOnChange}>
          <Space direction='vertical' >
            <Card title="Configuration" extra={renderPreviewOptions()} >
              <Space direction='vertical' size='large'>
                {renderSelfStudy()}
                {renderSections()}
                {renderActions()}
              </Space>
            </Card>
          </Space>
        </Form>
      </Space>
    );
  } else {
    return <Skeleton active={true} />
  }
}

export default SelfStudyConfigurationForm;