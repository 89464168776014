import { SaveOutlined, SendOutlined } from '@ant-design/icons';
import { Button, message, Modal, Skeleton, Space } from 'antd';
import Form from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import FormList from 'antd/lib/form/FormList';
import * as React from 'react';
import { useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyResponseApiService from '../../../api/SelfStudyResponseApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import Routes from '../../../config/Routes';
import AcademicYearStatusType from '../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import * as ApproveSelfStudyResponseHandler from '../../../handlerModels/ApproveSelfStudyResponseHandler';
import * as GetSelfStudyBySelfStudyResponseHandler from '../../../handlerModels/GetSelfStudyBySelfStudyResponseHandler';
import * as GetSelfStudyResponseByTypeHandler from '../../../handlerModels/GetSelfStudyResponseByTypeHandler';
import * as GetSelfStudyResponseLiteHandler from '../../../handlerModels/GetSelfStudyResponseLiteHandler';
import * as GetSelfStudyResponseProgramOverviewHandler from '../../../handlerModels/GetSelfStudyResponseProgramOverviewHandler';
import * as GetSelfStudySectionBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionBySectionOrderHandler';
import * as GetSelfStudySectionTemplateBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionTemplateBySectionOrderHandler';
import * as SaveSelfStudySectionHandler from '../../../handlerModels/SaveSelfStudySectionHandler';
import * as SubmitSelfStudyInstructorResponseHandler from '../../../handlerModels/SubmitSelfStudyInstructorResponseHandler';
import * as SaveSelfStudySectionFileHandler from '../../../handlerModels/SaveSelfStudySectionFileHandler';
import * as RemoveSelfStudySectionFileHandler from '../../../handlerModels/RemoveSelfStudySectionFileHandler';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudyResponseNewGoalDTO from '../../../models/SelfStudyResponseNewGoalDTO';
import SelfStudyResponsePreviousGoalDTO from '../../../models/SelfStudyResponsePreviousGoalDTO';
import SelfStudyResponseProgramOverviewDTO from '../../../models/SelfStudyResponseProgramOverviewDTO';
import SelfStudySectionComponentTypeDTO from '../../../models/SelfStudySectionComponentTypeDTO';
import SelfStudySectionResponseDTO from '../../../models/SelfStudySectionResponseDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import CurrentUser from '../../../utils/CurrentUser';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import GoBackButton from '../../buttons/GoBackButton';
import NextButton from '../../buttons/NextButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import ValueLabel from '../../general/ValueLabel';
import RichTextEditor from '../../inputs/RichTextEditor';
import SelfStudyConsultantReview from '../../secondary/SelfStudyConsultantReview';
import SelfStudyFreeTextQuestion from '../../secondary/SelfStudyFreeTextQuestion';
import SelfStudyInstruction from '../../secondary/SelfStudyInstruction';
import SelfStudyNewGoals from '../../secondary/SelfStudyNewGoals';
import SelfStudyPreviousGoals from '../../secondary/SelfStudyPreviousGoals';
import SelfStudyProgramOverview from '../../secondary/SelfStudyProgramOverview';
import SelfStudyRankingQuestion from '../../secondary/SelfStudyRankingQuestion';
import { UploadFile } from 'antd/lib/upload/interface';

interface CompleteSelfStudyFormProps extends BaseFormProps {
  selfStudyResponseId: string;
  selfStudyId?: string;
  academicYear?: number;
  readonly?: boolean;
  onSave?: (sectionIndex: number, fieldErrors?: any, disableNavigate?: boolean) => void;
  sectionIndex: number;
  stepSection?: number;
  selfStudyResponseType: number;
  detailsPage?: boolean;
  sectionLoaded?: boolean;
}

const CompleteSelfStudyForm = React.forwardRef((props: CompleteSelfStudyFormProps) => {
  const _formRef = React.useRef<any>();
  const _DEConsultantRef = React.useRef<any>();

  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudySection, setSelfStudySection] = useState({} as SelfStudySectionResponseDTO)
  const [responseComplete, setResponseComplete] = useState(false)
  const [selfStudySectionTemplate, setSelfStudySectionTemplate] = useState<SelfStudySectionTemplateDTO | null>(null)
  const [selfStudyResponseProgramOverview, setSelfStudyResponseProgramOverview] = useState({} as SelfStudyResponseProgramOverviewDTO);
  const [loading, setLoading] = useState(true);
  const [altered, setAltered] = useState(false);
  const [, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [componentTypes, setComponetTypes] = useState<SelfStudySectionComponentTypeDTO[]>([]);
  const [educatorSelfStudyResponse, setEducatorSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [rppSelfStudyResponse, setRPPSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [deSelfStudyResponse, setDOESelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [showDeniedModal, setShowDeniedModal] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, [props.sectionIndex, props.sectionLoaded]);

  const getSectionFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionResponseDTO.sectionComments, {
        name: [index, SelfStudySectionResponseDTO.sectionComments],
        label: 'Additional Comments',
      })
  }

  const fetchData = () => {
    setLoading(true);
    const loaders = [];
    window.scrollTo(0, 0);
    if (props.selfStudyResponseId && props.selfStudyResponseId != Guid.Empty() && props.sectionLoaded) {
      if (!selfStudyResponse.id || selfStudyResponse.id == Guid.Empty()) {
        loaders.push(loadSelfStudyResponse());
      }

      if (!selfStudy.id || selfStudy.id == Guid.Empty()) {
        loaders.push(loadSelfStudy(props.selfStudyResponseId));
      }

      if (!componentTypes || componentTypes.length == 0) {
        loaders.push(loadSelfStudySectionComponentTypes());
      }

      if (!selfStudySectionTemplate?.id || selfStudySectionTemplate?.id == Guid.Empty()) {
        loaders.push(loadSelfStudySectionTemplate());
      }

      if (!selfStudySection.id || selfStudySection.id == Guid.Empty()) {
        loaders.push(loadExisting());
      }

      if (props.selfStudyId && props.selfStudyId != Guid.Empty()) {
        loaders.push(loadEducatorSelfStudyResponse());
        loaders.push(loadRPPSelfStudyResponse());
        loaders.push(loadDOESelfStudyResponse());
      }

      Promise.all(loaders).then(() => {
        setLoading(false);
        resetForm();
      });
    }
  }

  const resetForm = () => {
    setAltered(true);
    _formRef.current?.resetFields();
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyBySelfStudyResponseHandler.Request.create({
      selfStudyResponseId: id
    });

    return SelfStudyApiService.getSelfStudyByResponse(request)
      .then((results: GetSelfStudyBySelfStudyResponseHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const loadEducatorSelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.INSTRUCTOR
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setEducatorSelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      }).catch(() => {
        setError(true);
      });
  }

  const loadRPPSelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.RPP
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setRPPSelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      }).catch(() => {
        setError(true);
      });
  }

  const loadDOESelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.DOE
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setDOESelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      }).catch(() => {
        setError(true);
      });
  }


  const loadSelfStudyResponseProgramOverview = (responseId: string) => {
    const request = GetSelfStudyResponseProgramOverviewHandler.Request.create({
      id: responseId
    });

    return SelfStudyApiService.getSelfStudyResponseProgramOverview(request)
      .then((results: GetSelfStudyResponseProgramOverviewHandler.Result) => {
        if (results.selfStudyResponseProgramOverview) {
          setSelfStudyResponseProgramOverview(results?.selfStudyResponseProgramOverview ?? SelfStudyResponseProgramOverviewDTO.create());
        }
      });
  }

  const loadSelfStudyResponse = () => {
    const request = GetSelfStudyResponseLiteHandler.Request.create({
      id: props.selfStudyResponseId
    });

    return SelfStudyApiService.getSelfStudyResponseLite(request)
      .then((results: GetSelfStudyResponseLiteHandler.Result) => {
        if (results.selfStudyResponse) {
          const completed =
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.INPROGRESS &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.REVISING &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.REVISIONSNEEDED &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.PENDING;

          setSelfStudyResponse(results.selfStudyResponse);
          setShowDeniedModal(!props.detailsPage && props.sectionIndex == 0 && results.selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && results.selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED)
          setResponseComplete(completed);
          loadSelfStudyResponseProgramOverview(results.selfStudyResponse?.id ?? Guid.Empty());
        }
      });
  }

  const loadExisting = () => {
    const request = GetSelfStudySectionBySectionOrderHandler.Request.create({
      selfStudyResponseId: props.selfStudyResponseId,
      academicYear: props.academicYear ?? 0,
      selfStudySectionOrder: props.sectionIndex
    });

    return SelfStudyApiService.getSelfStudySectionBySectionOrder(request)
      .then((results: GetSelfStudySectionBySectionOrderHandler.Result) => {
        if (results.selfStudySection) {
          setSelfStudySection(results.selfStudySection);
        }
        else {
          setSelfStudySection({} as SelfStudySectionResponseDTO);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSelfStudySectionTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplateBySectionOrder(props.academicYear ?? 0, props.sectionIndex)
      .then((results: GetSelfStudySectionTemplateBySectionOrderHandler.Result) => {
        if (results.selfStudySectionTemplate) {
          setSelfStudySectionTemplate(results.selfStudySectionTemplate);
        }
      });
  }

  const loadSelfStudySectionComponentTypes = () => {
    return LookupsUtil.getAll<SelfStudySectionComponentTypeDTO>(SelfStudySectionComponentTypeDTO.className)
      .then((results: SelfStudySectionComponentTypeDTO[]) => {
        setComponetTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const handleChange = () => {
    setAltered(true);
  }

  const onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: props.academicYear ?? 0, selfStudyResponseId: props.selfStudyResponseId, sectionIndex: props.sectionIndex - 1 }, { districtAdmin: props.readonly, step: props.stepSection }));
  }

  const onSubmit = () => {
    setSubmitting(true);
    const response = selfStudyResponse;
    const request = SubmitSelfStudyInstructorResponseHandler.Request.create({
      selfStudyResponseId: response?.id,
      selfStudyId: selfStudyResponse.selfStudyId,
      secondaryProgramStatusTypeId: _DEConsultantRef?.current?.getFieldsValue().secondaryProgramStatusType
    });

    SelfStudyApiService.submitSelfStudyInstructorResponse(request)
      .then((results: SubmitSelfStudyInstructorResponseHandler.Result) => {
        if (results.succeeded) {
          message.success('Submit Succeeded');
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyResponse.selfStudyId ?? 0 }, { academicYearId: props.academicYear }));
        }
        else {
          message.error(results.errors[0]);
        }
      })
      .catch(() => {
        message.error('Submit Failed');
        setError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const onApprove = () => {
    const response = selfStudyResponse;
    const request = ApproveSelfStudyResponseHandler.Request.create({
      selfStudyResponseId: response?.id,
      workItemKey: 'sss_' + props.academicYear + '_' + selfStudy.id + '_' + selfStudyResponse.selfStudyReponseTypeId
    });

    SelfStudyApiService.approveSelfStudyResponseHandler(request)
      .then((results: ApproveSelfStudyResponseHandler.Result) => {
        if (results.succeeded) {
          message.success('Approval Succeeded');
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyResponse.selfStudyId ?? 0 }, { academicYearId: props.academicYear }));
        }
        else {
          message.error('Approval Failed');
        }
      })
      .catch(() => {
        message.error('Approval Failed');
        setError(true);
      });
  }

  const getNewGoals = (selfStudySectionResponse: any) => {
    let newGoals: SelfStudyResponsePreviousGoalDTO[] = [];
    let newGoalIndex = 0;
    const index = selfStudySectionResponse?.selfStudySectionComponentResponses?.findIndex((x: any) => selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find(y => y.id == x.selfStudySectionComponentTemplateId)?.selfStudySectionComponentTypeId == SelfStudySectionComponentType.NEWGOALS);
    const isNewGoal = selfStudySectionResponse?.selfStudySectionComponentResponses[index];
    if (isNewGoal) {
      newGoals = selfStudySectionResponse.selfStudySectionComponentResponses.map((y: any) => {
        return SelfStudyResponseNewGoalDTO.create({
          ...y,
          id: selfStudyResponse && selfStudyResponse?.selfStudyResponseNewGoals ? (selfStudyResponse?.selfStudyResponseNewGoals ?? [])[newGoalIndex++]?.id : Guid.Empty(),
          section: y.section?.join(' / ')
        });
      });
    }

    return newGoals;
  }

  const getPreviousGoals = (selfStudySectionResponse: any) => {
    let previousGoals: SelfStudyResponsePreviousGoalDTO[] = [];
    let previousGoalIndex = 0;
    const index = selfStudySectionResponse?.selfStudySectionComponentResponses?.findIndex((x: any) => selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find(y => y.id == x.selfStudySectionComponentTemplateId)?.selfStudySectionComponentTypeId == SelfStudySectionComponentType.PREVIOUSGOALS);
    const isPreviousGoal = selfStudySectionResponse?.selfStudySectionComponentResponses[index];
    if (isPreviousGoal) {
      previousGoals = selfStudySectionResponse.selfStudySectionComponentResponses.map((y: any) => {
        return SelfStudyResponsePreviousGoalDTO.create({
          ...y,
          id: selfStudyResponse && selfStudyResponse?.selfStudyResponsePreviousGoals ? (selfStudyResponse?.selfStudyResponsePreviousGoals ?? [])[previousGoalIndex++]?.id : Guid.Empty(),
          section: y.section
        });
      });
    }

    return previousGoals;
  }

  const getProgramOverview = (selfStudySectionResponse: any) => {
    const programOverviewIndex = selfStudySectionResponse?.selfStudySectionComponentResponses?.findIndex((x: any) => selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find(y => y.id == x.selfStudySectionComponentTemplateId)?.selfStudySectionComponentTypeId == SelfStudySectionComponentType.PROGRAMOVERVIEW);
    const isProgramOverview = selfStudySectionResponse?.selfStudySectionComponentResponses[programOverviewIndex];
    if (isProgramOverview) {
      const program = selfStudySectionResponse?.selfStudySectionComponentResponses[programOverviewIndex];
      return SelfStudyResponseProgramOverviewDTO.create({
        ...program,
        id: selfStudyResponseProgramOverview.id ?? Guid.Empty(),
      });
    }

    return null;
  }

  const handleSubmit = (saveAndContinue?: boolean, submit?: boolean) => {
    setSaving(true);
    const form = (_formRef?.current as any).getFieldsValue();
    let deny: any = null;
    let request = {} as SaveSelfStudySectionHandler.Request;

    let selfStudySectionResponse: any = {};

    if (form?.selfStudySectionResponses) {
      selfStudySectionResponse = form.selfStudySectionResponses[0];
    }

    deny = (selfStudySectionResponse?.selfStudySectionComponentResponses ?? [])[selfStudySectionTemplate?.selfStudySectionComponentTemplates?.findIndex(x => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW) ?? 0]?.rppApproval;

    request = SaveSelfStudySectionHandler.Request.create({
      saveAndContinue: saveAndContinue,
      submit: submit,
      academicYear: props.academicYear,
      deniedApproval: deny,
      programStatusTypeId: (selfStudySectionResponse?.selfStudySectionComponentResponses ?? [])[selfStudySectionTemplate?.selfStudySectionComponentTemplates?.findIndex(x => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW) ?? 0]?.secondaryProgramStatusTypeId ?? 0,
      selfStudySectionResponse: SelfStudySectionResponseDTO.create({
        ...selfStudySectionResponse,
        academicYearId: props.academicYear,
        selfStudyId: props.selfStudyResponseId,
        id: selfStudySection.id,
        order: props.sectionIndex,
        selfStudyResponseId: selfStudyResponse?.id,
        selfStudySectionTemplateId: selfStudySectionTemplate?.id,
      }),
      selfStudyResponse: SelfStudyResponseDTO.create({
        ...selfStudyResponse,
        selfStudyResponsePreviousGoals: getPreviousGoals(selfStudySectionResponse),
        selfStudyResponseNewGoals: getNewGoals(selfStudySectionResponse),
        selfStudyResponseProgramOverview: getProgramOverview(selfStudySectionResponse)
      })
    });

    // Order goals
    let index = 0;
    request.selfStudyResponse?.selfStudyResponsePreviousGoals?.forEach(element => {
      element.order = index++;
    });

    request.selfStudyResponse?.selfStudyResponseNewGoals?.forEach(element => {
      element.order = index++;
    });

    // Clean resources
    for (const componentIndex in request.selfStudySectionResponse?.selfStudySectionComponentResponses) {
      {
        const component = request.selfStudySectionResponse?.selfStudySectionComponentResponses[componentIndex];
        component.selfStudySectionComponentResponseResources = [];
      }
    }

    SelfStudyApiService.saveSelfStudySection(request)
      .then((result: SaveSelfStudySectionHandler.Result) => {
        setSubmitted(true);
        if (saveAndContinue || submit) {
          if (result.denyApproval && submit) {
            message.success('Saved');
            HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyResponse.selfStudyId ?? 0 }, { academicYearId: props.academicYear }));
          }
          else {
            const index = (selfStudy.selfStudyTemplate?.selfStudySectionTemplates?.length ?? 0) - 1;

            if (result?.succeeded && index > props.sectionIndex) {
              fetchData();
              message.success('Saved');

              if (props.onSave) {
                props.onSave(props.sectionIndex + 1, null)
              }
            }
            else if (result?.succeeded && index <= props.sectionIndex) {
              setSelfStudySection(result.selfStudySection ?? SelfStudySectionResponseDTO.create());
              message.success('Saved');
              setFieldErrors(null);
              setError(false);
              onSubmit();
            }
            else {
              setError(!result?.succeeded);
              setFieldErrors(result?.fieldErrors);
              if (props.onSave) {
                props.onSave(props.sectionIndex + 1, result.fieldErrors)
              }

              message.error('Response could not be saved');
            }
          }
        }
        else {
          setSelfStudySection(result.selfStudySection ?? SelfStudySectionResponseDTO.create());
          _formRef.current?.setFieldsValue({ selfStudySectionResponses: [request.selfStudyResponse] })
          message.success('Saved');

          if (props.onSave) {
            props.onSave(props.sectionIndex, null, true)
          }
          setFieldErrors(null);
          setError(false);
          resetForm();
        }
      })
      .catch(() => {
        setError(true);
        message.error('Response could not be saved');
      })
      .finally(() => {
        setLoading(false);
        setSaving(false)
      });
  }

  const promptConfirmSubmit = () => {
    Modal.confirm({
      title: 'Are you sure you want to submit the response?',
      okText: 'Submit',
      onOk: () => handleSubmit(false, true)
    });
  }

  const onDeniedModalClose = () => {
    setShowDeniedModal(false);
  }

  const deniedResponse = () => {
    return <Modal
      visible={showDeniedModal}
      title={'Submission Denied'}
      onOk={onDeniedModalClose}
      footer={[
        <Button key='submit' type='primary' onClick={onDeniedModalClose}>
          Okay
        </Button>
      ]}>
      Please review consultant comments and make changes before resubmitting.
    </Modal>
  }

  const promptConfirmApprove = () => {
    Modal.confirm({
      title: 'Are you sure you want to approve the program?',
      okText: 'Approve',
      onOk: () => onApprove()
    });
  }

  const renderSave = () => {
    if (props.isEditing && loading != undefined && !loading) {
      const index = (selfStudy.selfStudyTemplate?.selfStudySectionTemplates?.length ?? 0) - 1;

      const approve = <Button type="primary" icon={<SendOutlined />} onClick={promptConfirmApprove} disabled={!altered} loading={saving}>Approve</Button>;
      const submit = <Button type="primary" icon={<SendOutlined />} onClick={promptConfirmSubmit} disabled={!altered} loading={submitting || saving}>Submit</Button>;
      const save = <Button icon={<SaveOutlined />} onClick={() => handleSubmit(false)} disabled={!altered} loading={saving}>Save</Button>;
      const responseComplete = selfStudyResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE;


      if ((!responseComplete && CurrentUser.Get()?.userId != selfStudyResponse.respondingUserId && !AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN, FeatureFlag.APPROVE_DISTRICT_RESPONSE])) || (CurrentUser.Get()?.userId != selfStudyResponse.respondingUserId && !AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]))) {
        return (
          <Space direction={'horizontal'} >
            {props.sectionIndex == 0 ? null : <GoBackButton onClick={onBack} />}
            {(index > props.sectionIndex && (CurrentUser.Get()?.userId != selfStudyResponse.respondingUserId || props.readonly)) ?
              <NextButton onClick={() => {
                if (props.onSave) {
                  props.onSave(props.sectionIndex + 1)
                }
              }} /> :
              null}
          </Space>
        );
      }

      if (responseComplete ||
        (!AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.APPROVE_DISTRICT_RESPONSE]) &&
          AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_DISTRICT_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) {
        if (CurrentUser.Get()?.userId == selfStudyResponse.respondingUserId && selfStudyResponse?.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.COMPLETE) {
          return (
            <Space direction={'horizontal'} >
              {props.sectionIndex == 0 ? null : <GoBackButton onClick={onBack} />}
              {index <= props.sectionIndex ? AuthorizationUtil.isAuthorized([FeatureFlag.APPROVE_DISTRICT_RESPONSE]) ? approve : null :
                <NextButton onClick={() => {
                  if (props.onSave) {
                    props.onSave(props.sectionIndex + 1)
                  }
                }} />
              }
            </Space>
          );
        }
        else {
          return (
            <Space direction={'horizontal'} >
              {props.sectionIndex == 0 ? null : <GoBackButton onClick={onBack} />}
              {index > props.sectionIndex && responseComplete ?
                <NextButton onClick={() => {
                  if (props.onSave) {
                    props.onSave(props.sectionIndex + 1)
                  }
                }} /> :
                null}
            </Space>
          );
        }
      }

      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.APPROVE_DISTRICT_RESPONSE]}>
          <Space direction={'horizontal'} >
            {props.sectionIndex == 0 ? null : <GoBackButton onClick={onBack} />}
            {index <= props.sectionIndex ?
              props.readonly ? AuthorizationUtil.isAuthorized([FeatureFlag.APPROVE_DISTRICT_RESPONSE]) ? approve : null :
                <Space>
                  {save}
                  {submit}
                </Space> :
              props.readonly ?
                < NextButton onClick={() => {
                  if (props.onSave) {
                    props.onSave(props.sectionIndex + 1)
                  }
                }} /> :
                <Space>
                  {save}
                  <SaveAndContinueButton onClick={() => handleSubmit(true)} disabled={!altered} submitting={saving} />
                </Space>}
          </Space>
        </AuthorizedContent>
      );
    }
  }

  const renderDistrictResponse = () => {
    const districtComments = educatorSelfStudyResponse.selfStudySectionResponses?.find(x => x.selfStudySectionTemplate?.order == props.sectionIndex)?.sectionComments ?? '';
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && (props.readonly || responseComplete))) {
      return renderExistingResponse('District Comments', districtComments);
    }
  }

  const renderRppResponse = () => {
    const rppComments = rppSelfStudyResponse.selfStudySectionResponses?.find(x => x.selfStudySectionTemplate?.order == props.sectionIndex)?.sectionComments ?? '';
    if (((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP && (props.readonly || responseComplete)))
      &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE) {
      return renderExistingResponse('Regional Planning Partner Comments', rppComments);
    }
  }

  const renderDEResponse = () => {
    const deComments = deSelfStudyResponse.selfStudySectionResponses?.find(x => x.selfStudySectionTemplate?.order == props.sectionIndex)?.sectionComments ?? '';
    if ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE && (props.readonly || responseComplete) &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      return renderExistingResponse('DE Comments', deComments);
    }
  }

  const renderExistingResponse = (title: string, comments: string,) => {
    return (
      <Space direction='vertical'>
        <ValueLabel text={title} />
        <RichTextEditor readonly={true || responseComplete} value={comments} />
      </Space>
    )
  }

  const renderSectionComments = () => {
    if (!(props.readonly || responseComplete)) {
      return <FormItem
        {...getSectionFormItems(0).get(SelfStudySectionResponseDTO.sectionComments)}
        {...ValidationUtil.getValidation(SelfStudySectionResponseDTO.sectionComments + selfStudySection?.selfStudySectionTemplate?.order, fieldErrors, submitted)} >
        <RichTextEditor readonly={props.readonly || responseComplete} />
      </FormItem>;
    }
  }

  const handleFileUpload = (componentTemplateIndex: number, sectionTemplateIndex: number | undefined, file: UploadFile | undefined) => {
    setSaving(true);

    const request = SaveSelfStudySectionFileHandler.Request.create({
      academicYear: props.academicYear,
      selfStudyResponseId: props.selfStudyResponseId,
      sectionTemplateIndex: sectionTemplateIndex,
      componentTemplateIndex: componentTemplateIndex,
      responseTypeId: props.selfStudyResponseType
    });

    const model = FileUploadUtil.attachFileToModel('saveSelfStudySectionFile', request, SaveSelfStudySectionFileHandler.Request.file, file);

    return SelfStudyApiService.saveSelfStudySectionFile(model)
      .then(() => {
        message.success('File Added');
      }).catch(() => {
        message.error('Error Adding File');
        setError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const handleFileRemoved = (componentTemplateIndex: number, sectionTemplateIndex: number | undefined, file: UploadFile | undefined) => {
    setSaving(true);

    const request = RemoveSelfStudySectionFileHandler.Request.create({
      academicYear: props.academicYear,
      selfStudyResponseId: props.selfStudyResponseId,
      sectionTemplateIndex: sectionTemplateIndex,
      componentTemplateIndex: componentTemplateIndex,
      responseTypeId: props.selfStudyResponseType,
      azureBlobFileId: file?.uid
    });

    return SelfStudyApiService.removeSelfStudySectionFile(request)
      .then(() => {
        message.success('File Removed');
      }).catch(() => {
        message.error('Error Removing File');
        setError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const renderComponent = (componentIndex: number) => {

    switch ((selfStudySectionTemplate?.selfStudySectionComponentTemplates ?? [])[componentIndex]?.selfStudySectionComponentTypeId) {
      case SelfStudySectionComponentType.INSTRUCTIONS:
        return <SelfStudyInstruction
          academicYear={props.academicYear}
          sectionIndex={props.sectionIndex}
          componentIndex={componentIndex}
          fieldErrors={fieldErrors}
          submitted={submitted} />;
      case SelfStudySectionComponentType.RANKINGQUESTION:
        return <SelfStudyRankingQuestion
          handleFileUpload={handleFileUpload}
          handleFileRemoved={handleFileRemoved}
          academicYear={props.academicYear}
          sectionIndex={props.sectionIndex}
          selfStudyId={props.selfStudyId}
          selfStudyResponseId={props.selfStudyResponseId}
          componentIndex={componentIndex}
          readonly={props.readonly || responseComplete}
          fieldErrors={fieldErrors}
          submitted={submitted} />;
      case SelfStudySectionComponentType.PREVIOUSGOALS:
        return (
          <SelfStudyPreviousGoals
            selfStudyResponseId={props.selfStudyResponseId}
            academicYear={props.academicYear}
            sectionIndex={props.sectionIndex}
            componentIndex={componentIndex}
            readonly={props.readonly || selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || responseComplete}
            fieldErrors={fieldErrors}
            submitted={submitted} />
        );
      case SelfStudySectionComponentType.NEWGOALS:
        return (
          <SelfStudyNewGoals
            selfStudyResponseId={props.selfStudyResponseId}
            academicYear={props.academicYear}
            sectionIndex={props.sectionIndex}
            componentIndex={componentIndex}
            readonly={props.readonly || selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || responseComplete}
            fieldErrors={fieldErrors}
            submitted={submitted} />
        );
      case SelfStudySectionComponentType.FREETEXTQUESTION:
        return <SelfStudyFreeTextQuestion
          handleFileUpload={handleFileUpload}
          handleFileRemoved={handleFileRemoved}
          academicYear={props.academicYear}
          selfStudyId={props.selfStudyId}
          selfStudyResponseId={props.selfStudyResponseId}
          sectionIndex={props.sectionIndex}
          componentIndex={componentIndex}
          readonly={props.readonly || responseComplete}
          fieldErrors={fieldErrors}
          submitted={submitted} />;
      case SelfStudySectionComponentType.PROGRAMOVERVIEW:
        return <SelfStudyProgramOverview
          academicYear={props.academicYear ?? 0}
          selfStudyResponseId={props.selfStudyResponseId}
          readonly={props.readonly || responseComplete}
          componentIndex={componentIndex}
          sectionIndex={props.sectionIndex}
          fieldErrors={fieldErrors}
          submitted={submitted}
          loading={loading} />;
      case SelfStudySectionComponentType.CONSULTANTREVIEW:
        if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR) {
          return (
            <SelfStudyConsultantReview
              handleFileUpload={handleFileUpload}
              handleFileRemoved={handleFileRemoved}
              academicYear={props.academicYear ?? 0}
              selfStudyResponseId={props.selfStudyResponseId}
              selfStudyId={props.selfStudyId}
              sectionIndex={props.sectionIndex}
              readonly={props.readonly || responseComplete}
              componentIndex={componentIndex}
              fieldErrors={fieldErrors}
              submitted={submitted} />
          );
        }
    }
  }

  const renderComponents = () => {
    return (
      <FormList name={[0, SelfStudySectionResponseDTO.selfStudySectionComponentResponses]} >
        {
          (components) => {
            return (
              <Space direction='vertical'>
                {
                  components.map((component, componentIndex) => {
                    if (component) {
                      let scenarios: string[] = [];
                      if (selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find((x: any) => x.order == componentIndex)?.selfStudySectionComponentScenarios) {
                        scenarios = selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find((x: any) => x.order == componentIndex)?.selfStudySectionComponentScenarios ?? [];
                      }

                      if (!selfStudyResponseProgramOverview.secondaryProgramScenarioId ||
                        ((scenarios?.length ?? 0) == 0) ||
                        (scenarios && scenarios.findIndex((x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)) {
                        return (
                          renderComponent(componentIndex)
                        );
                      }

                    }
                  })
                }
              </Space>
            );
          }
        }
      </FormList>
    );
  }

  const renderSection = () => {
    let scenarios: string[] = [];
    if (selfStudySectionTemplate?.selfStudySectionTemplateSecondaryProgramScenarios) {
      scenarios = selfStudySectionTemplate?.selfStudySectionTemplateSecondaryProgramScenarios ?? [];
    }

    const sectionNode = <FormList name={SelfStudyResponseDTO.selfStudySectionResponses} >
      {
        () => {
          return (

            <Space direction='vertical'>
              {
                renderComponents()
              }

              {
                (selfStudySectionTemplate?.selfStudySectionComponentTemplates?.findIndex(x => x.selfStudySectionComponentTypeId != SelfStudySectionComponentType.INSTRUCTIONS && (!selfStudyResponseProgramOverview.secondaryProgramScenarioId || (!x.selfStudySectionComponentScenarios || (x.selfStudySectionComponentScenarios?.length) == 0) ||
                  (x.selfStudySectionComponentScenarios && x.selfStudySectionComponentScenarios.findIndex((x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1))) ?? -1) > -1 &&
                  selfStudySectionTemplate?.selfStudySectionComponentTemplates?.findIndex(x => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW) == -1 ?
                  <>
                    {renderDistrictResponse()}
                    {renderRppResponse()}
                    {renderDEResponse()}
                    {renderSectionComments()}
                  </> :
                  null
              }
            </Space>
          );
        }
      }
    </FormList>;

    if (!selfStudyResponseProgramOverview.secondaryProgramScenarioId || (!scenarios || (scenarios?.length) == 0) ||
      (scenarios && scenarios.findIndex((x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)) {
      return (
        sectionNode
      );
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    const value = { ...selfStudyResponse, ...{ selfStudySectionResponses: [selfStudySection] } };
    return (
      <Space size="small" direction="vertical">
        {deniedResponse()}
        <Form
          ref={_formRef}
          layout="vertical"
          initialValues={value}
          onValuesChange={handleChange}
          requiredMark={true}>
          <Space direction='vertical'>
            {renderSection()}
            {props.detailsPage ? null : renderSave()}
          </Space >
        </Form>
      </Space >
    );
  }
})

export default CompleteSelfStudyForm;