import { Card, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import * as GetSelfStudyWithPreviousGoalsHandler from '../../handlerModels/GetSelfStudyWithPreviousGoalsHandler';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudyResponsePreviousGoalDTO from '../../models/SelfStudyResponsePreviousGoalDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import LabeledValue from '../general/LabeledValue';
import ReadableTextArea from '../inputs/ReadableTextArea';
import ReadableTextBox from '../inputs/ReadableTextBox';
import RichTextEditor from '../inputs/RichTextEditor';


interface SelfStudyPreviousGoalsProps extends BaseFormProps {
  academicYear?: number;
  selfStudyResponseId?: string;
  componentIndex: number;
  sectionIndex?: number;
  readonly?: boolean;
  disable?: boolean;
  previewComponent?: boolean;
  previousGoalCount?: number;
}

const SelfStudyPreviousGoals = React.forwardRef((props: SelfStudyPreviousGoalsProps) => {

  const getPreviousGoalsFormItems = (index: number, readonly: boolean) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponsePreviousGoalDTO.section, {
        required: !readonly,
        name: [index, SelfStudyResponsePreviousGoalDTO.section],
        label: 'Section',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponsePreviousGoalDTO.actionStepsAndTimeline, {
        required: !readonly,
        label: 'Action Step(s) And Timeline',
        name: [index, SelfStudyResponsePreviousGoalDTO.actionStepsAndTimeline],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponsePreviousGoalDTO.measureOfSuccess, {
        required: !readonly,
        label: 'Measure Of Success',
        name: [index, SelfStudyResponsePreviousGoalDTO.measureOfSuccess],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponsePreviousGoalDTO.results, {
        required: !readonly,
        label: 'Result',
        name: [index, SelfStudyResponsePreviousGoalDTO.results],
        rules: [ValidationRuleUtil.required()]
      })
  }

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState({} as SelfStudySectionComponentTemplateDTO);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 &&
      props.selfStudyResponseId != Guid.Empty() &&
      !props.previewComponent) {
      loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      loaders.push(loadSelfStudySectionComponentTemplate());

      Promise.all(loaders).then(() => {
        setLoading(false);
      });
    }
    else if (props.previewComponent) {
      loaders.push(loadSelfStudySectionComponentTemplate());
      setLoading(false);
    }
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithPreviousGoalsHandler.Request.create({
      selfStudyResponseId: id,
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyWithPreviousGoals(request)
      .then((results: GetSelfStudyWithPreviousGoalsHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());
        }
      });
  }

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex
    })
    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request)
      .then((results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create());
        }
      });
  }

  const renderPreviousResponse = (goalIndex: number) => {
    const instructorResponsePreviousGoals = (selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponsePreviousGoals ?? [])[goalIndex];
    return (
      <>
        <LabeledValue label={'Section'} text={instructorResponsePreviousGoals?.section} />
        <LabeledValue label={'Action Steps and Timeline'} text={instructorResponsePreviousGoals?.actionStepsAndTimeline} />
        <LabeledValue label={'Measure Of Success'} text={instructorResponsePreviousGoals?.measureOfSuccess} />
        <LabeledValue label={'Results'} text={instructorResponsePreviousGoals?.results} />
      </>
    );
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    const componentIndex = props.componentIndex;
    let previousGoalCount = selfStudySectionComponentTemplate?.numberOfPreviousGoals ?? 0;

    if (props.previewComponent) {
      previousGoalCount = props.previousGoalCount ?? 0;
    }

    const previousGoalNodes: any = [];

    for (let i = 0; i < (previousGoalCount ?? 0); i++) {

      const previousGoals = selfStudyResponse?.selfStudyResponsePreviousGoals ?
        (selfStudyResponse?.selfStudyResponsePreviousGoals ?? [])[i] :
        SelfStudyResponsePreviousGoalDTO.create();

      const readonly = previousGoals != undefined;
      previousGoalNodes.push(
        <Space direction='vertical' >
          <Card type='inner' title={'Goal ' + (i + 1)}>

            {selfStudyResponse?.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR && !props.previewComponent ?
              <Space direction='vertical'>
                {renderPreviousResponse(i)}
              </Space>
              :
              <>
                <FormItem
                  initialValue={previousGoals?.section}
                  {...getPreviousGoalsFormItems(i, readonly).get(SelfStudyResponsePreviousGoalDTO.section)}
                  {...ValidationUtil.getValidation(SelfStudyResponsePreviousGoalDTO.section + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextBox
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)}
                  />
                </FormItem>

                <FormItem
                  initialValue={previousGoals?.actionStepsAndTimeline}
                  {...getPreviousGoalsFormItems(i, readonly).get(SelfStudyResponsePreviousGoalDTO.actionStepsAndTimeline)}
                  {...ValidationUtil.getValidation(SelfStudyResponsePreviousGoalDTO.actionStepsAndTimeline + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextArea
                    showCount
                    maxLength={2000}
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)} />
                </FormItem>

                <FormItem
                  initialValue={previousGoals?.measureOfSuccess}
                  {...getPreviousGoalsFormItems(i, readonly).get(SelfStudyResponsePreviousGoalDTO.measureOfSuccess)}
                  {...ValidationUtil.getValidation(SelfStudyResponsePreviousGoalDTO.measureOfSuccess + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextArea
                    showCount
                    maxLength={2000}
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)} />
                </FormItem>

                <FormItem
                  initialValue={previousGoals?.results}
                  {...getPreviousGoalsFormItems(i, readonly).get(SelfStudyResponsePreviousGoalDTO.results)}
                  {...ValidationUtil.getValidation(SelfStudyResponsePreviousGoalDTO.results + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextArea
                    showCount
                    maxLength={2000}
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)} />
                </FormItem>
              </>
            }
          </Card>
        </Space>);
    }

    return (
      <Card type='inner' title='Previous Program Goals'>
        <RichTextEditor readonly={true} value={selfStudySectionComponentTemplate.primaryText} />
        {previousGoalNodes}
      </Card>
    );
  }

})

SelfStudyPreviousGoals.displayName = 'SelfStudyPreviousGoals';

export default SelfStudyPreviousGoals;