import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SelfStudyConfigurationForm from '../../../components/forms/secondary/SelfStudyConfigurationForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';


interface SelfStudyConfigurationPageProps {
  institution: InstitutionDTO | null;
}

interface SelfStudyConfigurationPageState extends BaseFormState {
  loading: boolean;
  academicYear: number;
}

class SelfStudyConfigurationPage extends React.Component<SelfStudyConfigurationPageProps & RouteComponentProps<RouteObject>, SelfStudyConfigurationPageState> {
  constructor(props: SelfStudyConfigurationPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0
    };
  }

  componentDidMount() {
    const academicYearId = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != academicYearId) {
      this.setState({ academicYear: academicYearId })
    }
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    let fromAcademicYearList = false;
    if (this.props.match.path.split('/').findIndex(x => x == 'academicYear-list') > -1) {
      fromAcademicYearList = true;
    }

    return (
      <Content >
        <HeaderPortal
          title={'Self Study Configuration'}
          breadcrumbs={fromAcademicYearList ? Breadcrumbs.selfStudyConfigurationFromAcademicList(this.state.academicYear) : Breadcrumbs.selfStudyConfiguration(this.state.academicYear)}
          onBack={() => this.onBack()} />
        <SelfStudyConfigurationForm academicYear={this.state.academicYear} />
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(SelfStudyConfigurationPage));
