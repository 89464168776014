import * as React from 'react';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface AcademicYearDataReviewStepsProps extends BaseFormProps {
  currentStep: number,
  academicYear: number
}

function AcademicYearDataReviewSteps(props: AcademicYearDataReviewStepsProps) {

  return (
    <CollapsibleSteps direction="vertical" current={props.currentStep}>
      <NavigationStep title='Area Education Agencies' route={Routes.generate(Routes.DATA_REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 0} />
      <NavigationStep title='Service Areas' route={Routes.generate(Routes.DATA_REVIEW_SERVICE_AREAS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 1} />
      <NavigationStep title='Federal Clusters' route={Routes.generate(Routes.DATA_REVIEW_FEDERAL_CLUSTERS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 2} />
      <NavigationStep title='Scenarios' route={Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_TYPES, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 3} />
      <NavigationStep title='Regional Planning Partners' route={Routes.generate(Routes.DATA_REVIEW_REGIONAL_PLANNING_PARTNER, { academicYear: props.academicYear }, {})} enabled={props.currentStep != 4} />
      <NavigationStep title='CIP Numbers' route={Routes.generate(Routes.DATA_REVIEW_SECONDARY_CIP_NUMBERS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 5} />
      <NavigationStep title='Districts' route={Routes.generate(Routes.DATA_REVIEW_DISTRICTS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 6} />
      <NavigationStep title='District Contacts' route={Routes.generate(Routes.DATA_REVIEW_DISTRICT_CONTACTS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 7} />
      <NavigationStep title='Programs' route={Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAMS, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 8} />
      <NavigationStep title='Courses' route={Routes.generate(Routes.DATA_REVIEW_SECONDARY_COURSES, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 9} />
      <NavigationStep title='Program Courses' route={Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_COURSES, { academicYearId: props.academicYear }, {})} enabled={props.currentStep != 10} />
    </CollapsibleSteps>
  );
}

export default AcademicYearDataReviewSteps;