import { Cascader, CascaderProps } from 'antd';
import * as React from 'react';
import ValueLabel from '../general/ValueLabel';

interface ReadableCascaderProps extends CascaderProps {
  readonly?: boolean;
}

function ReadableCascader(props: React.PropsWithChildren<ReadableCascaderProps>) {

  if (props.readonly) {
    if (props.value?.join) {
      return (
        <ValueLabel text={props.value?.join(' / ')} />
      );
    }
    else {
      return null;
    }
  }

  if (props.value?.join || props.value == undefined) {
    return (
      <Cascader {...props} />
    );
  }
  return null;

}

export default ReadableCascader;
