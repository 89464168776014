import { Skeleton } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import * as GetSelfStudySectionTemplateBySectionOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderHandler';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionTemplateDTO from '../../models/SelfStudySectionTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import ValidationUtil from '../../utils/ValidationUtil';
import RichTextEditor from '../inputs/RichTextEditor';


interface SelfStudyInstructionProps extends BaseFormProps {
  academicYear?: number;
  sectionIndex?: number;
  readonly?: boolean;
  componentIndex: number;
  onSave?: (secondaryProgramId: string) => void;
}

const SelfStudyInstruction = React.forwardRef((props: SelfStudyInstructionProps) => {

  const getComponentFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionComponentTemplateDTO.primaryText, {
        name: [props.componentIndex, SelfStudySectionComponentTemplateDTO.primaryText],
      });
  }

  const [loading, setLoading] = useState(false);
  const [selfStudySectionTemplate, setSelfStudySectionTemplate] = useState<SelfStudySectionTemplateDTO | null>(null)
  const [fieldErrors] = useState(props.fieldErrors);
  const [submitted] = useState(props.submitted);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.sectionIndex]
  )

  const fetchData = () => {
    setLoading(true);

    if (props.academicYear != 0 && props.sectionIndex != undefined) {
      loadSelfStudySectionTemplate();
    }
  }

  const loadSelfStudySectionTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplateBySectionOrder(props.academicYear ?? 0, props.sectionIndex ?? 0)
      .then((results: GetSelfStudySectionTemplateBySectionOrderHandler.Result) => {
        if (results.selfStudySectionTemplate) {
          setSelfStudySectionTemplate(results.selfStudySectionTemplate);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }


  if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Content>
        <FormItem
          initialValue={selfStudySectionTemplate?.selfStudySectionComponentTemplates?.find(x => x.order == props.componentIndex)?.primaryText}
          {...getComponentFormItems().get(SelfStudySectionComponentTemplateDTO.primaryText)}
          {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.primaryText + props.componentIndex, fieldErrors, submitted)} >
          <RichTextEditor readonly={true} />
        </FormItem>
      </Content>
    );
  }
})

SelfStudyInstruction.displayName = 'SelfStudyInstruction';

export default SelfStudyInstruction;