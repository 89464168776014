import { message, Select, Skeleton, Space, Table, Tooltip } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import * as GenerateSelfStudiesHandler from '../../../handlerModels/GenerateSelfStudiesHandler';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import * as GetSecondaryCipNumbresHandler from '../../../handlerModels/GetSecondaryCipNumbresHandler';
import * as GetSecondaryProgramsHandler from '../../../handlerModels/GetSecondaryProgramsHandler';
import * as GetSelfStudiesHandler from '../../../handlerModels/GetSelfStudiesHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import UserDTO from '../../../models/UserDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';

interface AddSelfStudyFormProps extends BaseFormProps {
  academicYear: number;
  selfStudy?: SelfStudyDTO[];
  onSubmit?: () => void;
  resetForm?: (value: string) => void;
}

const AddSelfStudyForm = React.forwardRef((props: AddSelfStudyFormProps, ref) => {
  const _formRef = React.createRef<FormInstance>();
  const _tableRef = React.createRef<any>();
  const _formItems = new Map<string, FormItemProps>()
    .set(DistrictDTO.academicYear, {
      label: 'Please select an program review year',
      required: true,
      name: DistrictDTO.academicYear,
    })
    .set(DistrictDTO.id, {
      label: 'Select District',
      required: true,
      name: DistrictDTO.id,
    })
    .set(UserDTO.roles, {
      label: 'Select User Roles',
      required: true,
      name: UserDTO.roles,
    });


  useImperativeHandle(ref, () => ({
    resetForm: () => props.resetForm,
    handleSubmit: () => handleSubmit()
  }));

  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [submitted, setSubmitted] = useState(false);
  const [districtId, setDistrictId] = useState(null);
  const [districts, setDistricts] = useState([] as DistrictDTO[]);
  const [secondaryProgram, setSecondaryProgram] = useState({} as SecondaryProgramDTO);
  const [secondaryPrograms, setSecondaryPrograms] = useState([] as SecondaryProgramDTO[]);
  const [selfStudies, setSelfStudies] = useState(props.selfStudy ?? [] as SelfStudyDTO[]);
  const [secondaryCipNumbers, setSecondaryCipNumbers] = useState([] as SecondaryCipNumberDTO[]);

  React.useEffect(() => {
    fetchData();
  }, [props.selfStudy]);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (!districts || districts.length == 0) {
      loaders.push(loadDistricts());
    }

    if (!secondaryCipNumbers || secondaryCipNumbers.length == 0) {
      loaders.push(loadSecondaryCipNumbers());
    }

    loaders.push(loadSelfStudies());

    Promise.all(loaders).then(() => {
      setLoading(false);
      if (props.resetForm) {
        props.resetForm;
      }
    });
  }

  const loadDistricts = () => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: props.academicYear
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          setDistricts(results.districts ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSecondaryPrograms = (districtId: string) => {
    const request = GetSecondaryProgramsHandler.Request.create({
      academicYearId: props.academicYear,
      districtId: districtId
    });
    SecondaryProgramApiService.getSecondaryPrograms(request)
      .then((results: GetSecondaryProgramsHandler.Result) => {
        if (results) {
          setSecondaryPrograms(results.secondaryPrograms ?? []);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const loadSelfStudies = () => {
    const request = GetSelfStudiesHandler.Request.create({
      academicYear: props.academicYear,
    });
    SelfStudyApiService.getSelfStudies(request)
      .then((results: GetSelfStudiesHandler.Result) => {
        if (results) {
          setSelfStudies(results.selfStudies ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }


  const loadSecondaryCipNumbers = () => {
    const request = GetSecondaryCipNumbresHandler.Request.create({
      academicYearId: props.academicYear,
    });
    SecondaryCipNumberApiService.getSecondaryCipNumbers(request)
      .then((results: GetSecondaryCipNumbresHandler.Result) => {
        if (results) {
          setSecondaryCipNumbers(results.secondaryCipNumbers ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const onSelect = (districtId: any) => {
    setDistrictId(districtId)
    loadSecondaryPrograms(districtId);
  }

  const handleSubmit = () => {
    const request = GenerateSelfStudiesHandler.Request.create({
      academicYearId: props.academicYear,
      secondaryProgramId: secondaryProgram.id
    });

    SelfStudyApiService.generateSelfStudies(request)
      .then((result: GenerateSelfStudiesHandler.Result) => {
        setSubmitted(true);
        if (result?.succeeded) {
          message.success('Program Added for Review');
        }
        else {
          setError(!result?.succeeded);
          setFieldErrors(result?.fieldErrors);
          if (result.fieldErrors[SelfStudyDTO.academicYearId]) {
            message.error(result.fieldErrors[SelfStudyDTO.academicYearId][0].message);
          }
          else {
            message.error('Program could not be added');
          }
        }
      })
      .catch(() => {
        setError(true);
        message.error('Program could not be added');
      })
      .finally(() => {
        setSubmitted(false)
        setLoading(false);
      });
  }

  const renderDistrict = (district: DistrictDTO) => {
    if (district.id) {
      return <Select.Option key={district.id ?? Guid.Empty()} value={district.id ?? Guid.Empty()}> {district.name}</Select.Option>
    }
  }

  const existingSecondaryProgram = () => {
    _tableRef.current?.refresh();
    return secondaryPrograms;

  }

  const handleSelected = (selectedRows: SecondaryProgramDTO[]) => {
    setSecondaryProgram(selectedRows[0]);
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Form
        ref={_formRef}
        layout="vertical"
        requiredMark={true}>
        <Space size="small" direction="vertical">

          <FormItem
            initialValue={districtId}
            {..._formItems.get(DistrictDTO.id)}
            {...ValidationUtil.getValidation(DistrictDTO.id, fieldErrors, submitted)}  >
            <Dropdown
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              onSelect={onSelect}
            >
              {districts.map(x => { return renderDistrict(x) })}
            </Dropdown>
          </FormItem>

          <Table dataSource={existingSecondaryProgram()}
            ref={_tableRef}
            pagination={false}
            rowKey={SecondaryProgramDTO.secondaryProgramId}
            rowClassName={record => selfStudies.findIndex(y => y.secondaryProgramId == record.id) == -1 ? '' : 'disabled-row'}
            rowSelection={{
              columnTitle: 'Actions',
              columnWidth: 100,
              type: 'radio',

              onChange: (selectedRowKeys: React.Key[], selectedRows: SecondaryProgramDTO[]) => {
                handleSelected(selectedRows);
              },
            }}
          >
            <Table.Column
              title='CIP Number'
              dataIndex={SecondaryProgramDTO.secondaryCipNumber}
              render={
                (value: any, record: SecondaryProgramDTO) => {
                  return (
                    <Tooltip title={secondaryCipNumbers.find(x => x.id == record.cipNumberId)?.code} placement='right'>
                      {secondaryCipNumbers.find(x => x.id == record.cipNumberId)?.code + ' - ' + secondaryCipNumbers.find(x => x.id == record.cipNumberId)?.description}
                    </Tooltip>
                  );
                }} />
          </Table>
        </Space >
      </ Form>
    );
  }

})

export default AddSelfStudyForm;