import * as React from 'react';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface CourseModficationChangeRequestStepsProps extends BaseFormProps {
  current: number;
  changeRequestId: string;
}

function CourseModificationChangeRequestSteps(props: CourseModficationChangeRequestStepsProps) {
  return (
    <CollapsibleSteps current={props.current} direction='vertical' >
      <NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Course Search' route={Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Course Details' route={Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_3, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 3 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />
      <NavigationStep title='Submitted' />
    </CollapsibleSteps >
  );
}

export default CourseModificationChangeRequestSteps;