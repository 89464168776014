import { Steps } from 'antd';
import * as React from 'react';
import Routes from '../../../config/Routes';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface NewTransferMajorProgramChangeRequestStepsProps {
  current: number;
  changeRequestId: string;
  changeRequest?: ChangeRequestDTO;
  awardId?: string;
}

function NewTransferMajorProgramChangeRequestSteps(props: NewTransferMajorProgramChangeRequestStepsProps) {
  let awardId = props.awardId ?? Guid.Empty();

  if (awardId == Guid.Empty()) {
    const awards = props.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards;
    if (awards && awards.length > 0) {
      awardId = awards[0].id ?? Guid.Empty()
    }
  }

  return (
    <CollapsibleSteps current={props.current} size='small' direction='vertical'>
      <NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Program Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Program Plan' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Contact Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_3, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 3 && props.changeRequestId != Guid.Empty()} title='Linking And Existing Entities' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_7, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 4 && props.changeRequestId != Guid.Empty()} title='Program Details' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_8, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 5 && props.changeRequestId != Guid.Empty()} title='Award Details' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_10, { id: props.changeRequestId, awardId: awardId ?? Guid.Empty() })} />
      <NavigationStep enabled={props.current != 6 && props.changeRequestId != Guid.Empty()} title='Term Details' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_11, { id: props.changeRequestId, awardId: awardId ?? Guid.Empty() })} />
      <NavigationStep enabled={props.current != 8 && props.changeRequestId != Guid.Empty()} title='ICCPHSE' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_12, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 12 && props.changeRequestId != Guid.Empty()} title='Sign Offs and Assurances' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_14, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 13 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_15, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 14 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />
      <Steps.Step title='Submitted' />
    </CollapsibleSteps>
  );
}

export default NewTransferMajorProgramChangeRequestSteps;
