import * as React from 'react';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';

interface NewDisciplineChangeRequestStep1FormProps extends BaseFormProps {
  current: number;
  changeRequestId: string;
}

function NewDisciplineChangeRequestSteps(props: NewDisciplineChangeRequestStep1FormProps) {
  return (
    <CollapsibleSteps current={props.current} direction='vertical' >
      <NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Discipline Details' route={Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />
      <NavigationStep title='Submitted' />
    </CollapsibleSteps>
  );
}

export default NewDisciplineChangeRequestSteps;