import * as React from 'react';
import Routes from '../../../config/Routes';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface NoticeOfIntentChangeRequestStepsProps {
  current: number;
  changeRequestId: string;
}

function NoticeOfIntentChangeRequestSteps(props: NoticeOfIntentChangeRequestStepsProps) {
  return (
    <CollapsibleSteps current={props.current} direction='vertical'>
      <NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Program Plan' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Planned Awards' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Contact Information' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_3, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 3 && props.changeRequestId != Guid.Empty()} title='Consultant Meetings' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_4, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 4 && props.changeRequestId != Guid.Empty()} title='Occupational Information' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_5, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 5 && props.changeRequestId != Guid.Empty()} title='Industry Information' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_6, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 6 && props.changeRequestId != Guid.Empty()} title='Linking And Existing Entities' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_7, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 7 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_8, { id: props.changeRequestId })} />
      <NavigationStep enabled={props.current != 8 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />
      <NavigationStep title='Submitted' />
    </CollapsibleSteps>
  );
}


export default NoticeOfIntentChangeRequestSteps;
