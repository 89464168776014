import * as React from 'react';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface OpeningAcademicYearStepsProps extends BaseFormProps {
  currentStep: number
}

function OpeningAcademicYearSteps(props: OpeningAcademicYearStepsProps) {

  return (
    <CollapsibleSteps direction="vertical" current={props.currentStep}>
      <NavigationStep title='Set Milestone Dates' />
      <NavigationStep title='Review Area Education Agency' />
      <NavigationStep title='Review Active Users' />
      <NavigationStep title='Review Course Data' />
      <NavigationStep title='Review Program Data' />
      <NavigationStep title='Start Program Review Year' />
    </CollapsibleSteps>
  );
}

export default OpeningAcademicYearSteps;