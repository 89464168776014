import { CloseCircleFilled } from '@ant-design/icons';
import * as React from 'react';
import Routes from '../../../config/Routes';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudySectionResponseDTO from '../../../models/SelfStudySectionResponseDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import StringUtil from '../../../utils/StringUtil';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface SelfStudyStepsProps extends BaseFormProps {
  selfStudySections?: SelfStudySectionResponseDTO[];
  selfStudySectionsTemplate?: SelfStudySectionTemplateDTO[];
  currentStep: number;
  selfStudyResponseId: string;
  selfStudyResponse: SelfStudyResponseDTO;
  academicYear: number;
  sectionIndex: number;
  districtAdmin: boolean;
  selfStudyResponseType: number;
  scenario: string | undefined | null
}

function SelfStudySteps(props: SelfStudyStepsProps) {
  let stepCount = -1;

  if (props.selfStudySectionsTemplate && props?.selfStudySectionsTemplate.length > 0) {
    const fieldErrors = props.fieldErrors

    return (
      <CollapsibleSteps current={props.currentStep} direction='vertical'>
        {props?.selfStudySectionsTemplate?.map(x => {
          if (x.selfStudySectionTemplateSecondaryProgramScenarios && (x.selfStudySectionTemplateSecondaryProgramScenarios.length == 0 || x.selfStudySectionTemplateSecondaryProgramScenarios.findIndex(x => x == (props.scenario ?? 0)) > -1)) {
            stepCount++;
            const sectionError = fieldErrors ? fieldErrors['selfStudyResponseDTO' + stepCount] ?? false : false;
            const title = sectionError ? <span><CloseCircleFilled color={'red'} /> {x?.title}</span> : StringUtil.shorten(x.title, 40, true);
            return <NavigationStep
              enabled={props.currentStep != stepCount}
              key={x.title}
              title={title}
              route={Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: props.academicYear, selfStudyResponseId: props.selfStudyResponseId, sectionIndex: x.order }, { districtAdmin: props.districtAdmin, selfStudyResponseType: props.selfStudyResponseType, step: stepCount })} />
          }
        }) ?? []}
      </CollapsibleSteps>
    );
  }

  return null;
}

export default SelfStudySteps;