import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Menu, message, Row, Skeleton } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import SelfStudySteps from '../../../components/displays/secondary/SelfStudySteps';
import CompleteSelfStudyForm from '../../../components/forms/secondary/CompleteSelfStudyForm';
import SelfStudyLayout from '../../../components/secondary/SelfStudyLayout';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import * as GetSelfStudyBySelfStudyResponseHandler from '../../../handlerModels/GetSelfStudyBySelfStudyResponseHandler';
import * as GetSelfStudyResponseLiteHandler from '../../../handlerModels/GetSelfStudyResponseLiteHandler';
import * as GetSelfStudySectionBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionBySectionOrderHandler';
import * as GetSelfStudySectionTemplateBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionTemplateBySectionOrderHandler';
import * as GetSelfStudyTemplateSectionsHandler from '../../../handlerModels/GetSelfStudyTemplateSectionsHandler';
import * as SelfStudyRevokeResponseHandler from '../../../handlerModels/SelfStudyRevokeResponseHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudySectionResponseDTO from '../../../models/SelfStudySectionResponseDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface CompleteSelfStudyPageProps {
  institution: InstitutionDTO | null;
}

interface CompleteSelfStudyPageState extends BaseFormState {
  loading: boolean;
  academicYear: number;
  selfStudyResponseId: string;
  selfStudy: SelfStudyDTO;
  selfStudyResponse: SelfStudyResponseDTO;
  selfStudySection: SelfStudySectionResponseDTO;
  selfStudySectionTemplate: SelfStudySectionTemplateDTO;
  selfStudyTemplateSectionTemplates: SelfStudySectionTemplateDTO[];
  sectionIndex: number;
  districtAdmin: boolean;
  selfStudyResponseType: number;
  sectionLoaded: boolean;
}

class CompleteSelfStudyPage extends React.Component<CompleteSelfStudyPageProps & RouteComponentProps<RouteObject>, CompleteSelfStudyPageState> {
  constructor(props: CompleteSelfStudyPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      selfStudyResponseId: Guid.Empty(),
      selfStudy: SelfStudyDTO.create(),
      selfStudySection: SelfStudySectionResponseDTO.create(),
      selfStudySectionTemplate: SelfStudySectionTemplateDTO.create(),
      sectionIndex: 0,
      selfStudyTemplateSectionTemplates: [],
      districtAdmin: false,
      selfStudyResponseType: 0,
      selfStudyResponse: SelfStudyResponseDTO.create(),
      sectionLoaded: false
    };
  }

  componentDidMount() {
    const academicYearId = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    const selfStudyResponseId = ParameterUtil.getPathPart(this.props.match, 'selfStudyResponseId');
    const sectionIndex = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'sectionIndex'));
    const districtAdmin = ParameterUtil.getParameter('districtAdmin');
    const selfStudyResponseType = Number.parseInt(ParameterUtil.getParameter('selfStudyResponseType'));

    if (this.state.academicYear != academicYearId) {
      this.setState({
        academicYear: academicYearId,
        loading: true,
        selfStudyResponseId: selfStudyResponseId,
        sectionIndex: sectionIndex,
        districtAdmin: districtAdmin == 'true' ? true : false,
        selfStudyResponseType: selfStudyResponseType,
        sectionLoaded: false
      });
      const loaders = [];
      loaders.push(this.loadSelfStudyResponse(selfStudyResponseId));
      loaders.push(this.loadSelfStudy(selfStudyResponseId));
      loaders.push(this.loadExisting(selfStudyResponseId, academicYearId, sectionIndex));
      loaders.push(this.loadSelfStudySectionTemplates(academicYearId));

      Promise.all(loaders).then(() => {
        this.setState({ loading: false })
      });
    }
  }

  componentDidUpdate() {
    const sectionIndex = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'sectionIndex'));
    if (sectionIndex != this.state.sectionIndex) {
      const selfStudyResponseId = ParameterUtil.getPathPart(this.props.match, 'selfStudyResponseId');
      const academicYearId = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
      const districtAdmin = ParameterUtil.getParameter('districtAdmin');
      const selfStudyResponseType = Number.parseInt(ParameterUtil.getParameter('selfStudyResponseType'));

      this.setState({
        sectionIndex: sectionIndex,
        loading: true, districtAdmin:
          districtAdmin == 'true' ? true : false,
        selfStudyResponseType: selfStudyResponseType,
        sectionLoaded: false
      });
      const loaders = [];
      loaders.push(this.loadExisting(selfStudyResponseId, academicYearId, sectionIndex));
      loaders.push(this.loadSelfStudy(selfStudyResponseId));
      loaders.push(this.loadSelfStudySectionTemplates(academicYearId));
      Promise.all(loaders).then(() => {
        this.setState({ loading: false })
      });
    }
  }

  private loadSelfStudyResponse = (id: string) => {
    const request = GetSelfStudyResponseLiteHandler.Request.create({
      id: id
    });

    return SelfStudyApiService.getSelfStudyResponseLite(request)
      .then((results: GetSelfStudyResponseLiteHandler.Result) => {
        if (results.selfStudyResponse) {
          this.setState({
            selfStudyResponse: results.selfStudyResponse,
            isNew: false,
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSelfStudy = (id: string) => {
    const request = GetSelfStudyBySelfStudyResponseHandler.Request.create({
      selfStudyResponseId: id
    });

    return SelfStudyApiService.getSelfStudyByResponse(request)
      .then((results: GetSelfStudyBySelfStudyResponseHandler.Result) => {
        if (results.selfStudy) {
          this.setState({
            selfStudy: results.selfStudy,
            selfStudyResponseId: results.selfStudy.id ?? Guid.Empty(),
            isNew: false,
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSelfStudySectionTemplates = (academicYear: any) => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplates(academicYear)
      .then((results: GetSelfStudyTemplateSectionsHandler.Result) => {
        if (results.selfStudySectionTemplates) {
          this.setState({
            selfStudyTemplateSectionTemplates: results.selfStudySectionTemplates,
            isNew: false,
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = (selfStudyResponseId: string, academicYearId: number, sectionIndex: any) => {
    const request = GetSelfStudySectionBySectionOrderHandler.Request.create({
      selfStudyResponseId: selfStudyResponseId,
      academicYear: academicYearId ?? 0,
      selfStudySectionOrder: sectionIndex,
      selfStudyResponseTypeId: SelfStudyResponseType.INSTRUCTOR,
      allowNew: true
    });
    return SelfStudyApiService.getSelfStudySectionBySectionOrder(request)
      .then((results: GetSelfStudySectionBySectionOrderHandler.Result) => {
        if (results.selfStudySection) {
          this.setState({
            selfStudySection: results.selfStudySection,
            isNew: false,
            sectionLoaded: true
          });
          this.loadSelfStudySectionTemplate(academicYearId, sectionIndex);
        }
        else {
          this.setState({
            selfStudySection: {} as SelfStudySectionResponseDTO,
            isNew: false,
          });
          this.loadSelfStudySectionTemplate(academicYearId, sectionIndex);
          this.loadSelfStudySectionTemplates(academicYearId);
        }
      });
  }

  private loadSelfStudySectionTemplate = (academicYearId: number, sectionIndex: any,) => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplateBySectionOrder(academicYearId, sectionIndex)
      .then((results: GetSelfStudySectionTemplateBySectionOrderHandler.Result) => {
        if (results.selfStudySectionTemplate) {
          this.setState({
            selfStudySectionTemplate: results.selfStudySectionTemplate,
          });
        }
      });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  private onSave = (sectionIndex: number, fieldErrors: any, disableNavigate?: boolean) => {
    if (disableNavigate) {
      this.setState({ fieldErrors: fieldErrors });
    }
    else {
      if (fieldErrors) {
        this.setState({ fieldErrors: fieldErrors });
      }
      else {
        const nextSection = this.nextSection(sectionIndex);
        const nextStep = this.nextStep();
        HistoryUtil.push(Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: this.state.academicYear, selfStudyResponseId: this.state.selfStudyResponse.id ?? Guid.Empty(), sectionIndex: nextSection }, { districtAdmin: this.state.districtAdmin, selfStudyResponseType: this.state.selfStudyResponseType, step: nextStep }));
      }
    }
    this.setState({ fieldErrors: fieldErrors });
  }

  private nextSection = (sectionIndex: number) => {
    const scenarios = this.state.selfStudyTemplateSectionTemplates.find(x => x.order == sectionIndex)?.selfStudySectionTemplateSecondaryProgramScenarios;
    const instiutitonResponse = this.state.selfStudy.selfStudyResponses?.find(y => y.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR);
    if (instiutitonResponse?.selfStudyResponseProgramOverview && instiutitonResponse?.selfStudyResponseProgramOverview?.secondaryProgramScenarioId) {
      const selectedScenario = instiutitonResponse?.selfStudyResponseProgramOverview?.secondaryProgramScenarioId;
      if (((scenarios?.length ?? -1) == 0 || (scenarios?.findIndex(x => x == selectedScenario) ?? -1) > -1)) {
        return sectionIndex;
      }
      else {
        return this.state.selfStudyTemplateSectionTemplates.find(x => ((x.selfStudySectionTemplateSecondaryProgramScenarios.findIndex(y => y == selectedScenario) ?? -1) > 0 || x.selfStudySectionTemplateSecondaryProgramScenarios.length == 0) && x.order > sectionIndex)?.order ?? 0;

      }
    }
    else {
      return sectionIndex;
    }

  }

  private nextStep = () => {
    const step = Number.parseInt(ParameterUtil.getParameter('step'));
    return step + 1;

  }

  private onRevokeClick = () => {
    const request = SelfStudyRevokeResponseHandler.Request.create({
      selfStudyResponseId: this.state.selfStudyResponse.id
    });

    SelfStudyApiService.revokeSelfStudyResponse(request)
      .then((results: SelfStudyRevokeResponseHandler.Result) => {
        if (results.succeeded) {
          message.success('Revoke Succeeded');
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: this.state.selfStudyResponse.selfStudyId ?? 0 }, { academicYearId: this.state.academicYear }));
        }
        else {
          message.error(results.errors[0]);
        }
      })
      .catch(() => {
        message.error('Revoke Failed');
      });
  }

  private renderRevokeResponse = () => {
    if ((this.state.selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.PENDINGAPPROVAL && this.state.selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.COMPLETE)) {
      const menu = (
        <Menu>
          {
            this.state.selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL || this.state.selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE ?
              <Menu.Item title='Revoke Response' onClick={this.onRevokeClick}
              >
                Revoke Response
              </Menu.Item>
              : null
          }
        </Menu>
      );

      return (
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      );
    }
  }

  private contentWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 18,
    xxl: 18,
  }
  render() {
    const academicYearId = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    const selfStudyResponseId = ParameterUtil.getPathPart(this.props.match, 'selfStudyResponseId');
    const selfStudyResponseType = Number.parseInt(ParameterUtil.getParameter('selfStudyResponseType'));
    const sectionIndex = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'sectionIndex'));
    const step = Number.parseInt(ParameterUtil.getParameter('step'));
    const title = this.state.loading ? '...' : this.state.isNew ? 'Adding Program for Review' : this.state.selfStudySectionTemplate.title ?? '...';
    const selectedScenario = this.state.selfStudy.selfStudyResponses?.find(y => y.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseProgramOverview?.secondaryProgramScenarioId;
    if (this.state.loading || this.state.academicYear == 0) {
      return (<Content >
        <HeaderPortal
          title={'Program ' + this.state.selfStudy?.secondaryProgram?.secondaryProgramId}
          breadcrumbs={Breadcrumbs.completeSelfStudy(title, (this.state.selfStudy?.secondaryProgram?.secondaryCipNumber?.description ?? '...'), academicYearId, this.state.selfStudyResponseId, step)}
          onBack={() => this.onBack()}
          extra={this.renderRevokeResponse()}
        />
        <Row gutter={[16, 16]}>
          <Col >
            <Card title='Steps'>
              <SelfStudySteps
                academicYear={academicYearId}
                selfStudyResponseId={selfStudyResponseId}
                sectionIndex={sectionIndex}
                selfStudyResponse={this.state.selfStudyResponse}
                selfStudySections={this.state.selfStudy.selfStudyResponses?.find(x => x.id == selfStudyResponseId)?.selfStudySectionResponses ?? undefined}
                selfStudySectionsTemplate={this.state.selfStudyTemplateSectionTemplates ?? undefined}
                currentStep={step}
                scenario={selectedScenario}
                districtAdmin={this.state.districtAdmin}
                selfStudyResponseType={selfStudyResponseType}
                fieldErrors={this.state.fieldErrors} />
            </Card>

          </Col>
          <Col {...this.contentWidth}>
            <Card title={title}>
              <Skeleton active />
            </Card>
          </Col>
        </Row>

      </Content>);
    }
    else {
      const readonly = this.state.selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL ||
        this.state.selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE ||
        (!AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE]) &&
          AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_DISTRICT_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE])) ||
        ((this.state.selfStudyResponse?.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.COMPLETE && CurrentUser.Get()?.userId != this.state.selfStudyResponse.respondingUserId) && !AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]));

      return (
        <>
          <SelfStudyLayout
            academicYear={academicYearId}
            selfStudyResponseId={selfStudyResponseId}
            sectionIndex={sectionIndex}
            selfStudyResponse={this.state.selfStudyResponse}
            selfStudySections={this.state.selfStudy.selfStudyResponses?.find(x => x.id == selfStudyResponseId)?.selfStudySectionResponses ?? undefined}
            selfStudySectionsTemplate={this.state.selfStudyTemplateSectionTemplates ?? undefined}
            currentStep={step}
            districtAdmin={this.state.districtAdmin}
            scenario={selectedScenario}
            selfStudyResponseType={selfStudyResponseType}
            title={this.state.selfStudy.secondaryProgram?.secondaryCipNumber?.description ?? '...'}
            subtitle={this.state.selfStudy.secondaryProgram?.district?.name ?? '...'}
            breadcrumbs={Breadcrumbs.completeSelfStudy(title, (this.state.selfStudy?.secondaryProgram?.secondaryCipNumber?.description ?? '...'), academicYearId, this.state.selfStudyResponseId, step)}
            onBack={this.onBack} renderRevokeResponse={this.renderRevokeResponse}
            fieldErrors={this.state.fieldErrors}>
            <Card title={title}>
              <CompleteSelfStudyForm
                academicYear={academicYearId}
                selfStudyResponseId={selfStudyResponseId}
                selfStudyId={this.state.selfStudy.id ?? Guid.Empty()}
                sectionIndex={sectionIndex}
                stepSection={step}
                isEditing={true}
                onSave={this.onSave}
                readonly={readonly}
                selfStudyResponseType={selfStudyResponseType}
                sectionLoaded={this.state.sectionLoaded} />
            </Card>
          </SelfStudyLayout>
        </>
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(CompleteSelfStudyPage));
