import { StepProps, Steps } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface NavigationStepProps extends StepProps {
  route?: string;
  enabled?: boolean;
}

function NavigationStep(props: React.PropsWithChildren<NavigationStepProps>) {
  let title = props.title
  if (props.enabled && props.route) {
    title = (
      <Link to={props.route}>{title}</Link>
    );
  }

  return <Steps.Step {...props} title={title} />;
}

export default NavigationStep;
