// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveProgramModificationChangeRequestStep1Handler from '../handlerModels/SaveProgramModificationChangeRequestStep1Handler';
import * as SaveProgramModificationChangeRequestStep4Handler from '../handlerModels/SaveProgramModificationChangeRequestStep4Handler';
import * as SaveProgramModificationChangeRequestStep5Handler from '../handlerModels/SaveProgramModificationChangeRequestStep5Handler';
import * as SearchForCoursesForProgramsHandler from '../handlerModels/SearchForCoursesForProgramsHandler';
import * as SearchForCourseBlocksForProgramsHandler from '../handlerModels/SearchForCourseBlocksForProgramsHandler';
import * as SubmitProgramModificationChangeRequestStep1Handler from '../handlerModels/SubmitProgramModificationChangeRequestStep1Handler';
import * as SubmitProgramModificationChangeRequestStep2Handler from '../handlerModels/SubmitProgramModificationChangeRequestStep2Handler';
import * as SubmitProgramModificationChangeRequestStep3Handler from '../handlerModels/SubmitProgramModificationChangeRequestStep3Handler';
import * as SubmitProgramModificationChangeRequestStep4Handler from '../handlerModels/SubmitProgramModificationChangeRequestStep4Handler';
import * as SubmitProgramModificationChangeRequestStep5Handler from '../handlerModels/SubmitProgramModificationChangeRequestStep5Handler';
import * as GetProgramModificationChangeRequestHandler from '../handlerModels/GetProgramModificationChangeRequestHandler';
import * as GetProgramModificationChangeRequestStep1Handler from '../handlerModels/GetProgramModificationChangeRequestStep1Handler';
import * as GetProgramModificationChangeRequestStep2Handler from '../handlerModels/GetProgramModificationChangeRequestStep2Handler';
import * as GetProgramModificationChangeRequestStep3Handler from '../handlerModels/GetProgramModificationChangeRequestStep3Handler';
import * as GetProgramModificationChangeRequestStep4Handler from '../handlerModels/GetProgramModificationChangeRequestStep4Handler';
import * as GetProgramModificationChangeRequestStep4TermHandler from '../handlerModels/GetProgramModificationChangeRequestStep4TermHandler';
import * as ValidateProgramModificationChangeRequestStep4Handler from '../handlerModels/ValidateProgramModificationChangeRequestStep4Handler';
import * as GetProgramModificationChangeRequestStep5Handler from '../handlerModels/GetProgramModificationChangeRequestStep5Handler';
import * as GetProgramModificationChangeRequestStep6Handler from '../handlerModels/GetProgramModificationChangeRequestStep6Handler';
import * as RemoveAwardFromProgramModificationProgramHandler from '../handlerModels/RemoveAwardFromProgramModificationProgramHandler';
import * as ExistingProgramModificationSearchHandler from '../handlerModels/ExistingProgramModificationSearchHandler';

export class ProgramModificationChangeRequestApiService extends BaseApi {

    // post: api/ProgramModificationChangeRequests/saveStep1
    public saveStep1(saveProgramModificationChangeRequestStep1: SaveProgramModificationChangeRequestStep1Handler.Request): Promise<SaveProgramModificationChangeRequestStep1Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramModificationChangeRequestStep1Handler.Result>(saveProgramModificationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/saveStep4
    public saveStep4(saveProgramModificationChangeRequestStep4: SaveProgramModificationChangeRequestStep4Handler.Request): Promise<SaveProgramModificationChangeRequestStep4Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/saveStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramModificationChangeRequestStep4Handler.Result>(saveProgramModificationChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/saveStep5
    public saveStep5(saveProgramModificationChangeRequestStep5: SaveProgramModificationChangeRequestStep5Handler.Request): Promise<SaveProgramModificationChangeRequestStep5Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/saveStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramModificationChangeRequestStep5Handler.Result>(saveProgramModificationChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/searchForCoursesForPrograms
    public searchForCoursesForPrograms(searchForCoursesForPrograms: SearchForCoursesForProgramsHandler.Request): Promise<SearchForCoursesForProgramsHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/searchForCoursesForPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCoursesForProgramsHandler.Result>(searchForCoursesForPrograms, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/searchForCourseBlocksForPrograms
    public searchForCourseBlocksForPrograms(searchForCourseBlocksForPrograms: SearchForCourseBlocksForProgramsHandler.Request): Promise<SearchForCourseBlocksForProgramsHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/searchForCourseBlocksForPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCourseBlocksForProgramsHandler.Result>(searchForCourseBlocksForPrograms, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/submitStep1
    public submitStep1(submitProgramModificationChangeRequestStep1: SubmitProgramModificationChangeRequestStep1Handler.Request): Promise<SubmitProgramModificationChangeRequestStep1Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramModificationChangeRequestStep1Handler.Result>(submitProgramModificationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/submitStep2
    public submitStep2(submitProgramModificationChangeRequestStep2: SubmitProgramModificationChangeRequestStep2Handler.Request): Promise<SubmitProgramModificationChangeRequestStep2Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/submitStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramModificationChangeRequestStep2Handler.Result>(submitProgramModificationChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/submitStep3
    public submitStep3(submitProgramModificationChangeRequestStep3: SubmitProgramModificationChangeRequestStep3Handler.Request): Promise<SubmitProgramModificationChangeRequestStep3Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/submitStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramModificationChangeRequestStep3Handler.Result>(submitProgramModificationChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/submitStep4
    public submitStep4(submitProgramModificationChangeRequestStep4: SubmitProgramModificationChangeRequestStep4Handler.Request): Promise<SubmitProgramModificationChangeRequestStep4Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/submitStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramModificationChangeRequestStep4Handler.Result>(submitProgramModificationChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/submitStep5
    public submitStep5(submitProgramModificationChangeRequestStep5: SubmitProgramModificationChangeRequestStep5Handler.Request): Promise<SubmitProgramModificationChangeRequestStep5Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/submitStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramModificationChangeRequestStep5Handler.Result>(submitProgramModificationChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/get
    public get(getProgramModificationChangeRequest: GetProgramModificationChangeRequestHandler.Request): Promise<GetProgramModificationChangeRequestHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestHandler.Result>(getProgramModificationChangeRequest, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep1
    public getStep1(getProgramModificationChangeRequestStep1: GetProgramModificationChangeRequestStep1Handler.Request): Promise<GetProgramModificationChangeRequestStep1Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep1Handler.Result>(getProgramModificationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep2
    public getStep2(getProgramModificationChangeRequestStep2: GetProgramModificationChangeRequestStep2Handler.Request): Promise<GetProgramModificationChangeRequestStep2Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep2Handler.Result>(getProgramModificationChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep3
    public getStep3(getProgramModificationChangeRequestStep3: GetProgramModificationChangeRequestStep3Handler.Request): Promise<GetProgramModificationChangeRequestStep3Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep3Handler.Result>(getProgramModificationChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep4
    public getStep4(getProgramModificationChangeRequestStep4: GetProgramModificationChangeRequestStep4Handler.Request): Promise<GetProgramModificationChangeRequestStep4Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep4Handler.Result>(getProgramModificationChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep4Term
    public getStep4Term(getProgramModificationChangeRequestStep4Term: GetProgramModificationChangeRequestStep4TermHandler.Request): Promise<GetProgramModificationChangeRequestStep4TermHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep4Term`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep4TermHandler.Result>(getProgramModificationChangeRequestStep4Term, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/validateProgramModificationChangeRequestStep4
    public validateProgramModificationChangeRequestStep4(validateProgramModificationChangeRequestStep4: ValidateProgramModificationChangeRequestStep4Handler.Request): Promise<ValidateProgramModificationChangeRequestStep4Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/validateProgramModificationChangeRequestStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ValidateProgramModificationChangeRequestStep4Handler.Result>(validateProgramModificationChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep5
    public getStep5(getProgramModificationChangeRequestStep5: GetProgramModificationChangeRequestStep5Handler.Request): Promise<GetProgramModificationChangeRequestStep5Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep5Handler.Result>(getProgramModificationChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/getStep6
    public getStep6(getProgramModificationChangeRequestStep6: GetProgramModificationChangeRequestStep6Handler.Request): Promise<GetProgramModificationChangeRequestStep6Handler.Result> {
        let url = `api/ProgramModificationChangeRequests/getStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramModificationChangeRequestStep6Handler.Result>(getProgramModificationChangeRequestStep6, 'post', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/removeAwardFromProgramModificationProgramHandler
    public removeAwardFromProgramModificationProgramHandler(removeAwardFromProgramModificationProgram: RemoveAwardFromProgramModificationProgramHandler.Request): Promise<RemoveAwardFromProgramModificationProgramHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/removeAwardFromProgramModificationProgramHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveAwardFromProgramModificationProgramHandler.Result>(removeAwardFromProgramModificationProgram, 'post', url, true, false);
    }

    // get: api/ProgramModificationChangeRequests/programComparisonReport/${encodeURIComponent(changeRequestId)}
    public programComparisonReport(changeRequestId: string): Promise<any> {
        let url = `api/ProgramModificationChangeRequests/programComparisonReport/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/ProgramModificationChangeRequests/programSideBySideComparisonReport/${encodeURIComponent(changeRequestId)}
    public programSideBySideComparisonReport(changeRequestId: string): Promise<any> {
        let url = `api/ProgramModificationChangeRequests/programSideBySideComparisonReport/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/ProgramModificationChangeRequests/previousProgramCurriculumReport/${encodeURIComponent(programDetailId)}
    public previousProgramCurriculumReport(programDetailId: string): Promise<any> {
        let url = `api/ProgramModificationChangeRequests/previousProgramCurriculumReport/${encodeURIComponent(programDetailId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/ProgramModificationChangeRequests/checkProgramModificationExisting
    public checkProgramModificationExisting(existingProgramModificationSearch: ExistingProgramModificationSearchHandler.Request): Promise<ExistingProgramModificationSearchHandler.Result> {
        let url = `api/ProgramModificationChangeRequests/checkProgramModificationExisting`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ExistingProgramModificationSearchHandler.Result>(existingProgramModificationSearch, 'post', url, true, false);
    }
}
var service = new ProgramModificationChangeRequestApiService();
export default service;
