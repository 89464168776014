import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Modal, Select, Skeleton, Space, Table } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SecondaryProgramScenarioApiService from '../../api/SecondaryProgramScenarioApiService';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSecondaryProgramScenariosHandler from '../../handlerModels/GetSecondaryProgramScenariosHandler';
import * as GetSelfStudyWithProgramOverviewHandler from '../../handlerModels/GetSelfStudyWithProgramOverviewHandler';
import SecondaryCourseDTO from '../../models/SecondaryCourseDTO';
import SecondaryProgramCourseDTO from '../../models/SecondaryProgramCourseDTO';
import SecondaryProgramScenarioDTO from '../../models/SecondaryProgramScenarioDTO';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudyResponseProgramOverviewDTO from '../../models/SelfStudyResponseProgramOverviewDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import SecondaryProgramScenarioDataTable from '../datatables/secondary/SecondaryProgramScenarioDataTable';
import ThirdPartyCredentialDisplay from '../displays/ThirdPartyCredentialDisplay';
import LabeledValue from '../general/LabeledValue';
import ValueLabel from '../general/ValueLabel';
import Dropdown from '../inputs/Dropdown';

interface SelfStudyProgramOverviewProps extends BaseFormProps {
  academicYear: number;
  selfStudyResponseId?: string;
  readonly?: boolean;
  disable?: boolean;
  componentIndex: number;
  sectionIndex?: number;
  previewComponent?: boolean;
}

function SelfStudyProgramOverview(props: SelfStudyProgramOverviewProps) {
  const YEAR_TO_HIDE = 2023;
  const getProgramOverviewFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponseProgramOverviewDTO.secondaryProgramScenarioId, {
        required: !props.readonly,
        name: [index, SelfStudyResponseProgramOverviewDTO.secondaryProgramScenarioId],
        label: <>Scenario<Button size='small' type='link' icon={<InfoCircleOutlined />} onClick={renderModal} /></>,
        rules: [ValidationRuleUtil.required()]
      })
  }

  const [loading, setLoading] = useState(true);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [, setError] = useState(false);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [secondaryProgramScenarios, setSecondaryProgramScenarios] = useState([] as SecondaryProgramScenarioDTO[]);
  const [showScenarioInfo, setShowScenarioInfo] = useState(false);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 && props.selfStudyResponseId != Guid.Empty() && !props.previewComponent) {
      if ((!selfStudy.id || selfStudy.id == Guid.Empty()) && loading) {

        loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      }
      loaders.push(loadSecondaryProgramScenarios());
      Promise.all(loaders).then(() => {
        setLoading(false);
      });
    }

    if (props.previewComponent) {
      setLoading(false);
    }
  }

  const loadSecondaryProgramScenarios = () => {
    const request = GetSecondaryProgramScenariosHandler.Request.create({
      academicYearId: props.academicYear ?? 0
    });

    return SecondaryProgramScenarioApiService.getSecondaryProgramScenarios(request)
      .then((results: GetSecondaryProgramScenariosHandler.Result) => {
        if (results) {
          setSecondaryProgramScenarios(results.secondaryProgramScenario ?? []);
        }

      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithProgramOverviewHandler.Request.create({
      selfStudyResponseId: id
    });

    return SelfStudyApiService.getSelfStudyWithProgramOverview(request)
      .then((results: GetSelfStudyWithProgramOverviewHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());
        }
      }).catch(() => {
        setError(true);
      });
  }

  const renderSecondaryProgramScenarios = (SecondaryProgramScenario: SecondaryProgramScenarioDTO) => {
    if (SecondaryProgramScenario.id) {
      return <Select.Option title={SecondaryProgramScenario.title ?? ''} key={SecondaryProgramScenario.id ?? Guid.Empty()} value={SecondaryProgramScenario.id ?? Guid.Empty()}>{SecondaryProgramScenario.title}</Select.Option>
    }
  }

  const renderModal = () => {
    setShowScenarioInfo(true);
  }

  const closeScenarioModal = () => {
    setShowScenarioInfo(false);
  }

  const sumCarnegieUnit = () => {
    let count = 0;
    selfStudy.secondaryProgram?.secondaryProgramCourses?.forEach(x => count += x.course?.carnegieUnit ?? 0);
    return count;
  }

  const renderPreviousResponse = () => {
    {
      const instructorResponseProgramOverview = selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseProgramOverview;
      return (
        <>
          <LabeledValue label={'Scenario'} text={secondaryProgramScenarios.find(x => x.id == instructorResponseProgramOverview?.secondaryProgramScenarioId)?.description} />
        </>
      );
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    const instructorResponseProgramOverview = selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseProgramOverview
    return (
      <Card type='inner' title='Program Information'>
        <Modal visible={showScenarioInfo} closable={true} title="Scenario Information" width={1000} onCancel={closeScenarioModal} footer={null}>
          <SecondaryProgramScenarioDataTable fromProgramOverview={true} academicYear={props.academicYear ?? 0}></SecondaryProgramScenarioDataTable>
        </Modal>

        <Space direction='vertical' size='large'>
          <Descriptions size='small' bordered={true} layout='horizontal' column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}>
            <Descriptions.Item label='Program Review Year'>{selfStudy.academicYearId + ' - ' + (selfStudy.academicYearId + 1)}</Descriptions.Item>
            <Descriptions.Item label='Program Information Year'>{selfStudy.academicYear?.programInfoYear + ' - ' + ((selfStudy.academicYear?.programInfoYear ?? 0) + 1)}</Descriptions.Item>
            <Descriptions.Item label='Title'>{selfStudy.secondaryProgram?.secondaryCipNumber?.description}</Descriptions.Item>
            <Descriptions.Item label="Regional Planning Partner">{selfStudy.secondaryProgram?.district?.region?.name}</Descriptions.Item>
            <Descriptions.Item label="Community College">{selfStudy.secondaryProgram?.district?.region?.institution?.name}</Descriptions.Item>
            <Descriptions.Item label="District">{selfStudy.secondaryProgram?.district?.display}</Descriptions.Item>
            <Descriptions.Item label="CIP Number">{selfStudy?.secondaryProgram?.secondaryCipNumber?.code}</Descriptions.Item>
            <Descriptions.Item label="Total Carnegie Unit">{sumCarnegieUnit()}</Descriptions.Item>
          </Descriptions >

          <ValueLabel value='Courses'></ValueLabel>

          <Table
            className='disable-table-hover'
            dataSource={selfStudy.secondaryProgram?.secondaryProgramCourses ?? []}
            pagination={false}
            scroll={{ x: 500 }}
          >
            <Table.Column
              title="Course Number"
              width={125} dataIndex={SecondaryCourseDTO.localCourseNumber}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.course?.localCourseNumber;
                }
              } />
            <Table.Column
              title="Title"
              dataIndex={SecondaryCourseDTO.courseName}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.course?.courseName;
                }
              } />
            <Table.Column
              title="Carnegie Units"
              dataIndex={SecondaryCourseDTO.carnegieUnit}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.course?.carnegieUnit;
                }
              } />
            <Table.Column
              title="Enrollment"
              dataIndex={SecondaryCourseDTO.enrollment}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.course?.enrollment;
                }
              } />
            <Table.Column
              title="Offer and Teach"
              dataIndex={SecondaryProgramCourseDTO.isOfferTeach}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.isOfferTeach ? 'Yes' : 'No';
                }
              } />
            <Table.Column
              title="SCED"
              dataIndex={SecondaryCourseDTO.sced}
              render={
                (data: any, row: SecondaryProgramCourseDTO) => {
                  return row.course?.sced;
                }
              } />
          </ Table>

          {selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR ?

            <Space direction='vertical'>
              {renderPreviousResponse()}
            </Space> :

            <Space direction='vertical'>
              <FormItem
                initialValue={(selfStudyResponse.selfStudyResponseProgramOverview as any)?.secondaryProgramScenarioId}
                {...getProgramOverviewFormItems(props.componentIndex).get(SelfStudyResponseProgramOverviewDTO.secondaryProgramScenarioId)}
                {...ValidationUtil.getValidation(SelfStudyResponseProgramOverviewDTO.secondaryProgramScenarioId + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >
                {!props.disable && (props.readonly) ? <ValueLabel text={secondaryProgramScenarios?.find(x => x.id == (selfStudyResponse.selfStudyResponseProgramOverview as any)?.secondaryProgramScenarioId)?.title} /> :
                  <Dropdown readOnly={!props.disable && (props.readonly)} dropdownMatchSelectWidth={false} disabled={loading || props.disable}>
                    {secondaryProgramScenarios?.map(x => renderSecondaryProgramScenarios(x))}
                  </Dropdown>
                }
              </FormItem>
            </ Space>
          }
          {loading || props.academicYear <= YEAR_TO_HIDE ? null :
            <ThirdPartyCredentialDisplay
              thirdPartyCredentials={instructorResponseProgramOverview?.thirdPartyCredentials ?? []}
              isSecondaryProgram={true}
              sectionIndex={props.sectionIndex}
              componentIndex={props.componentIndex}
              readonly={(selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR ?? true) || loading}
              submitted={submitted}
              fieldErrors={fieldErrors} />
          }
        </Space>
      </Card >
    );
  }
}

SelfStudyProgramOverview.displayName = 'SelfStudyProgramOverview';

export default SelfStudyProgramOverview;