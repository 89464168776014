import { Card, Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudySteps from '../displays/secondary/SelfStudySteps';
import HeaderPortal from '../../portals/HeaderPortal';
import SelfStudySectionResponseDTO from '../../models/SelfStudySectionResponseDTO';
import SelfStudySectionTemplateDTO from '../../models/SelfStudySectionTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';

interface SelfStudyLayoutProps extends BaseFormProps {
  academicYear: number;
  breadcrumbs: any[];
  title: string;
  subtitle: string;
  selfStudySections?: SelfStudySectionResponseDTO[];
  selfStudySectionsTemplate?: SelfStudySectionTemplateDTO[];
  currentStep: number;
  selfStudyResponseId: string;
  selfStudyResponse: SelfStudyResponseDTO;
  sectionIndex: number;
  districtAdmin: boolean;
  selfStudyResponseType: number;
  scenario: string | undefined | null
  onBack: () => void;
  renderRevokeResponse: () => void;
}

function SelfStudyLayout(props: React.PropsWithChildren<SelfStudyLayoutProps>) {
  const stepWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 6,
    xxl: 6,
  }

  const contentWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 18,
    xxl: 18,
  }

  const formWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
  }

  const steps =
    <SelfStudySteps
      academicYear={props.academicYear}
      selfStudyResponseId={props.selfStudyResponseId}
      sectionIndex={props.sectionIndex}
      selfStudyResponse={props.selfStudyResponse}
      selfStudySections={props.selfStudySections}
      selfStudySectionsTemplate={props.selfStudySectionsTemplate}
      currentStep={props.currentStep}
      districtAdmin={props.districtAdmin}
      scenario={props.scenario}
      selfStudyResponseType={props.selfStudyResponseType}
      fieldErrors={props.fieldErrors} />;

  const size = useBreakpoint();

  let stepsAndComments = <div />;
  if (size.xxl || size.xl) {
    stepsAndComments = (
      <Col >
        <Card title='Steps'>
          {steps}
        </Card>
      </Col>);
  }
  else {
    stepsAndComments = (
      <Card>
        {steps}
      </Card >
    );
  }

  return (
    <Content >
      <HeaderPortal
        title={props.title}
        breadcrumbs={props.breadcrumbs}
        onBack={props.onBack}
        extra={props.renderRevokeResponse}
      />

      <Row gutter={[16, 16]}>
        <Col {...stepWidth} >
          {stepsAndComments}
        </Col>

        <Col {...contentWidth} >
          <Row gutter={[16, 16]}>
            <Col {...formWidth}>
              {props.children}
            </Col >
          </Row>
        </Col>
      </Row>
    </Content >
  );
}

export default SelfStudyLayout;