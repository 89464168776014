import { Descriptions, message, Skeleton, Space } from 'antd';
import Form from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetSelfStudyDetailsHandler from '../../../handlerModels/GetSelfStudyDetailsHandler';
import * as SaveSelfStudyDetailsHandler from '../../../handlerModels/SaveSelfStudyDetailsHandler';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyStatusTypeDTO from '../../../models/SelfStudyStatusTypeDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface SelfStudyDetailsFormProps extends BaseFormProps {
  selfStudyId: string;
  academicYearId?: number;
  readonly?: boolean;
  onSave?: (secondaryProgramId: string) => void;
}

const SelfStudyDetailsForm = React.forwardRef((props: SelfStudyDetailsFormProps, ref) => {
  const _formRef = React.useRef<any>();

  const _formItems = new Map<string, FormItemProps>()
    .set(SelfStudyDTO.id, {
      required: true,
      name: SelfStudyDTO.id,
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SelfStudyDTO.secondaryProgramId, {
      required: true,
      name: SelfStudyDTO.secondaryProgramId,
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  useImperativeHandle(ref, () => ({
    resetForm: () => resetForm()
  }));

  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);

  const [loading, setLoading] = useState(true);
  const [altered, setAltered] = useState(false);
  const [, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selfStudyStatusTypes, setSelfStudyStatusTypes] = useState([] as SelfStudyStatusTypeDTO[]);

  React.useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];
    loaders.push(loadSelfStudy());

    if (!selfStudyStatusTypes || selfStudyStatusTypes.length == 0) {
      loaders.push(loadSelfStudyStatusTypes());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
      resetForm();
    });
  }

  const loadSelfStudy = () => {
    if (loading) {
      if (props.selfStudyId == Guid.Empty() || props.isNew) {
        loadNew();
      }
      else {
        loadExisting();
      }
    }
  }

  const resetForm = () => {
    _formRef.current?.resetFields();
    setAltered(true);
  }

  const loadExisting = () => {
    if (props.selfStudyId && props.academicYearId) {
      const request = GetSelfStudyDetailsHandler.Request.create({
        id: props.selfStudyId
      });
      SelfStudyApiService.getSelfStudyDetails(request)
        .then((results: GetSelfStudyDetailsHandler.Result) => {
          if (results) {
            setSelfStudy(results.selfStudy ?? SelfStudyDTO.create({
              id: props.selfStudyId == Guid.Empty() ? undefined : props.selfStudyId,
              secondaryProgramId: undefined,
              selfStudyStepId: undefined,
              selfStudyStatusTypeId: undefined,
            }));

            resetForm();
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }

  const loadSelfStudyStatusTypes = () => {
    return LookupsUtil.getAll<SelfStudyStatusTypeDTO>(SelfStudyStatusTypeDTO.className)
      .then((results: SelfStudyStatusTypeDTO[]) => {
        if (results) {
          setSelfStudyStatusTypes(results);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const loadNew = () => {
    setLoading(false);
    setSelfStudy(SelfStudyDTO.create({
      id: props.selfStudyId == Guid.Empty() ? undefined : props.selfStudyId,
      secondaryProgramId: undefined,
      selfStudyStepId: undefined,
      selfStudyStatusTypeId: undefined,
    }));
  }

  const handleChange = () => {
    setAltered(true);
  }

  const handleSubmit = () => {
    setSubmitting(true);

    const request = SaveSelfStudyDetailsHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudy: SelfStudyDTO.create({
        ...(_formRef.current ? (_formRef?.current as any).getFieldsValue() : null),
        academicYearId: props.academicYearId,
      })
    });

    SelfStudyApiService.saveSelfStudyDetails(request)
      .then((result: SaveSelfStudyDetailsHandler.Result) => {
        setSubmitted(true);
        if (result?.succeeded) {
          setSelfStudy(result.selfStudy ?? SelfStudyDTO.create({
            id: props.selfStudyId == Guid.Empty() ? undefined : props.selfStudyId,
            secondaryProgramId: undefined,
            selfStudyStepId: undefined,
            selfStudyStatusTypeId: undefined,
          }));
          setFieldErrors(null);
          setError(false);

          message.success('Saved');

          if (props.onSave && result.selfStudy?.id) {
            props.onSave(result.selfStudy.id);
            resetForm();
          }
          loadExisting();

        }
        else {
          setError(!result?.succeeded);
          setFieldErrors(result?.fieldErrors);
          message.error('Program Review could not be saved');
        }
      })
      .catch(() => {
        setError(true);
        message.error('Program Review could not be saved');
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false)
      });
  }

  const renderSave = () => {
    if (props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_REVIEW]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!altered} saving={submitting} />
            <ResetButton disabled={!altered} onConfirm={resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  if (loading || !selfStudy.id) {
    return <Skeleton active />
  }
  else {

    return (
      <Space size="small" direction="vertical">

        <Form
          ref={_formRef}
          layout="vertical"
          initialValues={selfStudy}
          onValuesChange={handleChange}
          onFinish={handleSubmit}
          requiredMark={true}>

          <Descriptions size='small' bordered={true} layout='vertical' column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }}>
            <Descriptions.Item label="Id"> {
              props.readonly || !props.isEditing ? selfStudy.id :
                <FormItem
                  className={'description-container'}
                  {..._formItems.get(SelfStudyDTO.id)}
                  {...ValidationUtil.getValidation(SelfStudyDTO.id, fieldErrors, submitted)}>
                  <ReadableTextBox disabled={!props.isEditing || loading || submitting} />
                </FormItem>
            } </Descriptions.Item>

            <Descriptions.Item label="District"> {
              selfStudy.secondaryProgram?.district?.name
            } </Descriptions.Item>

            <Descriptions.Item label="CIP Number"> {
              selfStudy.secondaryProgram?.secondaryCipNumber?.code
            } </Descriptions.Item>

          </Descriptions>
          {renderSave()}
        </Form>
      </Space >
    );
  }

})

export default SelfStudyDetailsForm;